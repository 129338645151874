import { postRequest, BaseURL, getRequest } from "apis/axios.handler";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LogoutOutlined, RequestQuote } from "@mui/icons-material";
import QuoteDashboard from "./QuoteDashboard";
import SendIcon from "@material-ui/icons/Send";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Divider,
  List,
} from "@material-ui/core";
import moment from "moment";
import { Menu, Close, Chat } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  menuSliderContainer: {
    width: 250,
    background: "white",
    height: "100%",
  },
  avatar: {
    margin: "0.5rem auto",
    padding: "1rem",
    width: 200, // Decrease the size here
    height: theme.spacing(8), // Decrease the size here
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      objectFit: "contain", // Add this line to prevent the image from being stretched
    },
  },
  listItem: {
    color: "tan",
  },
}));

const IPATAChat = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [currentChat, setCurrentChat] = useState(null);
  const [chats, setChats] = useState([]);
  const agentId = location.state.agentId;
  const emailId = location.state.emailId;
  const company = location.state.company;
  const chatContainerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);

  const toggleSlider = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const token = localStorage.getItem("agentAuthToken");
    getRequest({
      endpoint: "/getImportChats",
      params: { agentId: agentId.toString(), token: token },
      options: { baseURL: BaseURL.AIChatService },
    }).then((response) => {
      if (response.data.data.length === 0) {
        setChats([
          {
            conversation_id: 0,
            last_message_timestamp: Date.now(),
          },
        ]);
      } else {
        setChats(response.data.data);
      }
      if (response.data.data[0])
        setCurrentChat(response.data.data[0].conversation_id);
      else setCurrentChat(0);
    });
  }, [agentId]);

  const handleSend = () => {
    if (input.trim() === "") return;

    const userMessage = { role: "user", content: input.trim() };
    if (messages === null) setMessages([userMessage]);
    else setMessages([...messages, userMessage]);

    const token = localStorage.getItem("agentAuthToken");

    setTimeout(() => {
      postRequest({
        endpoint: "/promptImportChatbot",
        data: {
          prompt: userMessage,
          agentId: agentId,
          token: token,
          conversationId: currentChat,
        },
        options: { baseURL: BaseURL.AIChatService },
      })
        .then((response) => {
          const assistantMessage = {
            role: "assistant",
            content: response.data.data.content,
          };
          if (messages === null) setMessages([userMessage, assistantMessage]);
          else setMessages([...messages, userMessage, assistantMessage]);
        })
        .catch(() => {
          alert(
            "There is some issue with the chatbot, please try again later."
          );
        });
    }, 1000);

    setInput("");
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    const token = localStorage.getItem("agentAuthToken");
    getRequest({
      endpoint: "/getImportChatbotHistory",
      params: {
        agentId: agentId.toString(),
        token: token,
        conversationId: currentChat,
      },
      options: { baseURL: BaseURL.AIChatService },
    })
      .then((response) => {
        const firstMessage = {
          role: "assistant",
          content:
            "Hi! I am Smart Pet Air Travel's AI Assistant! How can I help you with your USA import service needs?",
        };
        if (!response.data.data) {
          setMessages([firstMessage]);
          return;
        }
        setMessages([firstMessage, ...response.data.data]);
      })
      .catch(() => {
        alert(
          "There is some issue with loading chat history. Please try again later."
        );
      });
  }, [agentId, currentChat]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleLogout = () => {
    localStorage.setItem("agentAuthToken", "");
    navigate("/ipata");
  };

  const handleNewChat = () => {
    const new_id =
      chats.length > 0
        ? Math.max(...chats.map((o) => o.conversation_id)) + 1
        : 0;
    const newChat = {
      conversation_id: new_id,
      last_message_timestamp: Date.now(),
    };
    setChats([...chats, newChat]);
    setCurrentChat(newChat.conversation_id);
    setMessages([
      {
        role: "assistant",
        content:
          "Hi! I am Smart Pet Air Travel's AI Assistant! How can I help you with your USA import service needs?",
      },
    ]);
  };

  const handleChatClick = (chatId) => {
    setCurrentChat(chatId);
    setPage(1);
  };

  return (
    <React.Fragment>
      <Box component="nav" className="hidden sm:block">
        <AppBar position="static" style={{ backgroundColor: "#c2867b" }}>
          <Toolbar>
            <IconButton onClick={toggleSlider} style={{ color: "white" }}>
              <Menu />
            </IconButton>
            <Drawer open={open} anchor="left" onClose={toggleSlider}>
              <Box className={classes.menuSliderContainer} component="div">
                <Box display="flex" justifyContent="flex-end">
                  <IconButton onClick={toggleSlider} style={{ color: "tan" }}>
                    <Close />
                  </IconButton>
                </Box>
                <Avatar
                  className={classes.avatar}
                  src="/images/img_logo.svg"
                  alt="Avatar"
                />
                <Divider />
                <List>
                  <ListItem
                    button
                    onClick={() => {
                      setOpen(false);
                      setPage(0);
                    }}
                  >
                    <ListItemIcon>
                      <Chat style={{ color: "tan" }} />
                    </ListItemIcon>
                    <ListItemText primary="Chats" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      setOpen(false);
                      setPage(2);
                    }}
                  >
                    <ListItemIcon>
                      <RequestQuote style={{ color: "tan" }} />
                    </ListItemIcon>
                    <ListItemText primary="Quotes Dashboard" />
                  </ListItem>
                  <ListItem button onClick={handleLogout}>
                    <ListItemIcon>
                      <LogoutOutlined style={{ color: "tan" }} />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </ListItem>
                </List>
              </Box>
            </Drawer>
          </Toolbar>
        </AppBar>
      </Box>
      <div className="flex h-screen sm:h-[90vh]">
        <div
          className={`w-1/6 bg-gray-100 p-4 overflow-y-auto sm:${
            page === 0 ? "w-full" : "hidden"
          }`}
        >
          <header className="text-center mb-6">
            <img
              src="images/img_logo.svg"
              alt="Logo"
              onClick={() => navigate("/")}
              className="mx-auto h-24 w-40 cursor-pointer"
            />
            <div className="flex flex-row w-[100%] items-center">
              <LogoutOutlined
                style={{ color: "gray" }}
                onClick={handleLogout}
                className="cursor-pointer mr-4"
              />
              <h1 className="text-xl font-bold text-gray-700 flex-grow text-center">
                IPATA Agent Portal
              </h1>
            </div>
          </header>
          <div className="text-sm text-gray-700 font-bold flex-grow text-center pb-4">
            {emailId}
          </div>
          <button
            onClick={handleNewChat}
            className="text-stone-300 font-bold w-full bg-blurmine-100 text-white p-2 rounded-md hover:bg-blurmine-50 mb-4"
          >
            New Chat
          </button>

          {chats.map((chat) => (
            <div
              key={chat.conversation_id}
              onClick={() => handleChatClick(chat.conversation_id)}
              className={`p-2 mb-2 cursor-pointer rounded-md flex flex-row justify-between ${
                currentChat === chat.conversation_id
                  ? "bg-blue-200"
                  : "bg-gray-300"
              }`}
            >
              <div>Chat {chat.conversation_id + 1}</div>
              <div>
                {Math.abs(
                  moment
                    .duration(
                      moment(chat.last_message_timestamp.$date).diff(moment.now())
                    )
                    .asHours()
                ) > 24
                  ? moment(chat.last_message_timestamp.$date).fromNow()
                  : moment(chat.last_message_timestamp.$date).format("HH:mm")}
              </div>
            </div>
          ))}
        </div>
        <div
          className={`flex flex-col sm:${
            page === 1 ? "w-full" : "hidden"
          } w-2/6 p-4`}
        >
          <div className="flex flex-col items-center">
            <h1 className="text-2xl font-bold text-gray-700 flex-grow text-center">
              Smart Pet Air Travel
            </h1>
            <h1 className="text-xl font-bold text-gray-700 flex-grow text-center">
              Import Chatbot
            </h1>
          </div>
          <div
            ref={chatContainerRef}
            className="flex-grow overflow-y-auto mt-4 mb-4 p-2 border rounded-lg h-64"
          >
            {messages?.map((msg, index) => (
              <div
                key={index}
                className={`p-2 rounded-md mb-2 flex flex-row justify-between ${
                  msg.role === "user"
                    ? "bg-blue-100 self-end ml-10"
                    : "bg-gray-100 self-start mr-10"
                }`}
              >
                <p className="text-sm whitespace-pre-wrap">{msg.content}</p>
                <p className="text-xs pl-2 self-end">{moment(msg.timestamp).utcOffset(-5).format("HH:mm")}</p>
              </div>
            ))}
          </div>
          <div className="flex">
            <textarea
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyDown}
              className="flex-grow p-2 border rounded-l-md focus:outline-none focus:border-blue-500 resize-none"
              placeholder="Type your message..."
              onInput={(e) => {
                e.target.style.height = "auto";
                e.target.style.height = `${e.target.scrollHeight}px`;
                if (parseInt(e.target.style.height) > 150) {
                  e.target.style.height = "150px";
                  e.target.style.overflowY = "scroll";
                } else {
                  e.target.style.overflowY = "hidden";
                }
              }}
            />
            <button
              onClick={handleSend}
              className="text-stone-300 font-bold bg-blurmine-100 text-white p-2 rounded-r-md hover:bg-blurmine-50"
            >
              <SendIcon />
            </button>
          </div>
        </div>
        <div
          className={`flex flex-col sm:${
            page === 2 ? "w-full" : "hidden"
          } w-3/6 p-4`}
        >
          <QuoteDashboard
            isAdminDashboard={false}
            agentId={agentId}
            company={company}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default IPATAChat;
