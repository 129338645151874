import React, { useEffect, useRef, useState } from "react";
import { Box, IconButton, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { TextareaAutosize } from "@mui/material";
import { fetchExportChatHistory, promptExportChatbot } from "apis/ai_chatbot.api";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  selectedListItem: {
    backgroundColor: theme.palette.action.selected,
  },
  messageContainer: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  petDetailsContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  keyText: {
    fontWeight: "bold",
  },
  blueText: {
    color: "#0000FF", // Blue color for Name, Phone, and Email
  },
  greenBoldText: {
    fontWeight: "bold",
    color: "#008000", // Green color for Pet 1 Details
  },
}));
const AiChatBot = ({ clientId, agentId }) => {
  const [message, setMessage] = useState("");
  let [messages, setMessages] = useState([]);
  const classes = useStyles();
  const messagesEndRef = useRef(null);

  useEffect(() => {
    fetchExportChatHistory(clientId.toString()).then((data) => {
      if (data.data === undefined) return;
      setMessages(data.data);
      scrollToBottom();
    });
  }, [clientId]);

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSendMessage = () => {
    setMessage("");
    if (messages) {
      messages = [...messages, { role: "user", content: message }];
    } else {
      messages = [{role: "user", content: message}];
    }
    setMessages(messages);
    scrollToBottom();
    if (message.trim()) {
      promptExportChatbot(message, clientId.toString()).then((data) => {
        if (data === undefined) return;
        setMessages([...messages, data.data]);
        scrollToBottom();
      });
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };

  return (
    <>
      <div
        className="flex-grow overflow-auto p-4 space-y-4 h-[80vh] overflow-x-auto"
        ref={messagesEndRef}
      >
        {messages?.map((chat, index) => (
          <div
            key={index}
            className={`flex ${
              chat.role === "user" ? "justify-end" : "justify-start"
            } p-2`}
          >
            {/* Check if message contains a colon to determine maxWidth */}
            <div
              className={`rounded-lg p-2 ${
                chat.role === "user" ? "bg-gray-1900" : "bg-white-A700"
              }  w-auto ${
                chat.content.includes(":") ? "max-w-xs" : "max-w-full"
              } break-words`}
            >
              {chat.content.split("\n").map((line, lineIndex) => {
                const keyMatch = line.match(/(.*?):\s*(.*)/);
                let keyStyleClass = classes.keyText;
                let valueStyleClass = "";

                return (
                  <>
                    <Box key={lineIndex} my={1} style={{ maxWidth: 280 }}>
                      {keyMatch ? (
                        <>
                          <Typography
                            component="span"
                            className={keyStyleClass}
                          >
                            {keyMatch[1]}:
                          </Typography>{" "}
                          <Typography
                            component="span"
                            className={valueStyleClass}
                          >
                            {keyMatch[2]}
                          </Typography>
                        </>
                      ) : (
                        <Typography component="span">{line}</Typography>
                      )}
                    </Box>
                  </>
                );
              })}
            </div>
          </div>
        ))}
      </div>
      {/* Input field and send button */}
      <div className="m-4 flex-none">
        <div className="flex justify-center items-center rounded-lg border">
          <TextField
            style={{ backgroundColor: "#ffffff" }}
            fullWidth
            placeholder="Type message here"
            variant="outlined"
            size="medium"
            multiline
            value={message}
            onChange={handleMessageChange}
            InputProps={{
              inputComponent: TextareaAutosize,
              inputProps: {
                minRows: 1,
                maxRows: 4,
                style: { resize: "none" },
              },
            }}
          />
          <IconButton
            color="primary"
            component="span"
            onClick={handleSendMessage}
          >
            <SendIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default AiChatBot;
