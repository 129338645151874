import Pusher from 'pusher-js';

let pusherInstance = null;

export const getPusherInstance = () => {
  if (!pusherInstance) {
    pusherInstance = new Pusher('f4b1d8d2257ca2f60d57', {
      cluster: 'us2',
      encrypted: true,
    });
  }
  return pusherInstance;
};