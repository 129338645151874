import React from 'react'
import { Typography } from '@mui/material';

const Total = ({totalPrice}) => {
  return (
    
      <Typography variant="h6" className="text-sm font-semibold">
  Total: ${totalPrice}
</Typography>
    
  )
}

export default Total
