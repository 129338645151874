import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  CssBaseline,
  Drawer,
  CircularProgress,
} from "@material-ui/core";
import { Menu, ContactMail, Close } from "@material-ui/icons";
import InboxPanel from "./InboxPanel"; // Assuming this is an external component you're importing
import Repository from "./Repository";
import GoogleLoginComponent from "components/GoogleLogin/google-login";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  FileUploadOutlined,
  LogoutOutlined,
  RequestQuote,
} from "@mui/icons-material";
import QuoteDashboard from "pages/IPATAChat/QuoteDashboard";
import ExportQuoteDashboard from "./ExportQuoteDashboard";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const useStyles = makeStyles((theme) => ({
  menuSliderContainer: {
    width: 250,
    background: "white",
    height: "100%",
  },
  avatar: {
    margin: "0.5rem auto",
    padding: "1rem",
    width: 200, // Decrease the size here
    height: theme.spacing(8), // Decrease the size here
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      objectFit: "contain", // Add this line to prevent the image from being stretched
    },
  },
  listItem: {
    color: "tan",
  },
}));

function SignIn({ onSignIn, setIsLoading }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === "admin" && password === "password") {
      onSignIn();
    } else {
      alert("Invalid credentials");
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        "& .MuiTextField-root": {
          m: 1,
          width: "300px",
          "& input": {
            padding: "18.5px 14px", // Standard padding for Material-UI TextField
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "4px", // Rounds the corners slightly.
            "& fieldset": {
              borderColor: "#ccc", // Light grey border.
            },
            "&:hover fieldset": {
              borderColor: "#3f51b5", // Change border color on hover.
            },
            "&.Mui-focused fieldset": {
              borderColor: "#3f51b5", // Border color when the TextField is focused.
              borderWidth: "2px", // Make border thicker when focused to match Material-UI's style.
            },
          },
        },
        "& .MuiInputLabel-outlined": {
          transform: "translate(14px, 20px) scale(1)", // Adjust label position to match the input padding
          "&.MuiInputLabel-shrink": {
            transform: "translate(14px, -6px) scale(0.75)", // Adjust label position when it's shrunk after focusing
          },
        },
        "& .MuiButton-root": {
          mt: 2,
          width: "150px",
          textTransform: "none", // Stops the text from being all uppercase.
          backgroundColor: "#3f51b5", // Ensures the button has a primary color.
          color: "#fff", // White text color.
          "&:hover": {
            backgroundColor: "#303f9f", // Darken the button color on hover.
          },
        },
      }}
    >
      {/* <TextField
        label="Username"
        variant="outlined"
        required
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        margin="normal"
      />
      <TextField
        label="Password"
        variant="outlined"
        type="password"
        required
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        margin="normal"
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        Sign In
      </Button> */}
      <img
        src="/images/img_logo.svg"
        alt="SMART Pet Air Travel"
        className="h-16 sm:h-9 mb-12"
      />
      <GoogleLoginComponent handleSignIn={onSignIn} setLoading={setIsLoading} />
    </Box>
  );
}

function SideBar() {
  const location = useLocation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(location.state ? location.state.page : 0);
  window.history.replaceState({}, '');

  const toggleSlider = () => {
    setOpen(!open);
  };

  const sideList = () => (
    <Box className={classes.menuSliderContainer} component="div">
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={toggleSlider} style={{ color: "tan" }}>
          <Close />
        </IconButton>
      </Box>
      <Avatar
        className={classes.avatar}
        src="/images/img_logo.svg"
        alt="Avatar"
      />
      <Divider />
      <List>
        <ListItem
          button
          onClick={() => {
            setOpen(false);
            setPage(0);
          }}
        >
          <ListItemIcon>
            <ContactMail style={{ color: "tan" }} />
          </ListItemIcon>
          <ListItemText primary="Customer Inbox" />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            setOpen(false);
            setPage(1);
          }}
        >
          <ListItemIcon>
            <ContactMail style={{ color: "tan" }} />
          </ListItemIcon>
          <ListItemText primary="Agent Inbox" />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            setOpen(false);
            setPage(2);
          }}
        >
          <ListItemIcon>
            <RequestQuote style={{ color: "tan" }} />
          </ListItemIcon>
          <ListItemText primary="Export Dashboard" />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            setOpen(false);
            setPage(3);
          }}
        >
          <ListItemIcon>
            <RequestQuote style={{ color: "tan" }} />
          </ListItemIcon>
          <ListItemText primary="Import Dashboard" />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            setOpen(false);
            setPage(4);
          }}
        >
          <ListItemIcon>
            <FileUploadOutlined style={{ color: "tan" }} />
          </ListItemIcon>
          <ListItemText primary="Repository" />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            localStorage.setItem("authToken", "");
            window.location.reload();
          }}
        >
          <ListItemIcon>
            <LogoutOutlined style={{ color: "tan" }} />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <CssBaseline />
      <Box component="nav">
        <AppBar position="static" style={{ backgroundColor: "#c2867b" }}>
          <Toolbar>
            <IconButton onClick={toggleSlider} style={{ color: "white" }}>
              <Menu />
            </IconButton>
            <div className="flex flex-row items-center">
              <div className="flex flex-col pl-4">
                <p className="sm:hidden text-xl">Smart Pet Air Travel</p>
                <p className="sm:hidden">
                  {page === 0
                    ? "Customer Inbox"
                    : page === 1
                    ? "Agent Inbox"
                    : page === 2
                    ? "Export Dashboard"
                    : page === 3
                    ? "Import Dashboard"
                    : "Repository"}
                </p>
              </div>
            </div>
            <Drawer open={open} anchor="left" onClose={toggleSlider}>
              {sideList()}
            </Drawer>
          </Toolbar>
        </AppBar>
      </Box>
      {page === 0 && <InboxPanel isCustomerInbox={true} />}
      {page === 1 && <InboxPanel isCustomerInbox={false} />}
      {page === 2 && (
        <div className="px-4 mt-4">
          <ExportQuoteDashboard />
        </div>
      )}
      {page === 3 && (
        <div className="px-4 mt-4">
          <QuoteDashboard isAdminDashboard={true} agentId={-1} company={""} />
        </div>
      )}
      {page === 4 && <Repository />}
    </>
  );
}

export default function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleSignIn = () => {
    setIsSignedIn(true);
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setIsSignedIn(true);
    }
    setIsLoading(false);
  }, []);

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <React.StrictMode>
        <div>
          {isLoading ? (
            <Box
              display="flex"
              flexDirection={"column"}
              justifyContent="center"
              alignItems="center"
              minHeight="100vh"
            >
              <img
                src="/images/img_logo.svg"
                alt="SMART Pet Air Travel"
                className="h-16 sm:h-9 mb-12"
              />
              <CircularProgress color="primary" />
            </Box>
          ) : null}
          {!isLoading && isSignedIn ? <SideBar /> : null}
          {!isLoading && !isSignedIn ? (
            <SignIn onSignIn={handleSignIn} setIsLoading={setIsLoading} />
          ) : null}
        </div>
      </React.StrictMode>
    </GoogleOAuthProvider>
  );
}
