import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { getRequest, postRequest, BaseURL } from "apis/axios.handler";

import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';

const Repository = () => {
  const RepoState = {
    AIRLINES: 'Airlines',
    AIRLINE_RATES: 'Airline Rates',
    BANNED_BREEDS: 'Banned Breeds',
    COUNTRY_PROFILES: 'Country Profiles',
    CRATES: 'Crates',
    IPATA_MEMBERS: 'IPATA Members',
    CHATBOT_CONFIG: "Chatbot Config",
  };

  const [repo, setRepo] = useState("Select a");

  const AirlinesRepo = () => {
    const [airlines, setAirlines] = useState([]);
    const [filteredAirlines, setFilteredAirlines] = useState(airlines);
    const [allCountries, setAllCountries] = useState([]);

    const [airlineSearchTerm, setAirlineSearchTerm] = useState("");
    const [airlineToAdd, setAirlineToAdd] = useState("");
    const [file, setFile] = useState();
    const fileInputRef = useRef(null);
    const [showAirline, setShowAirline] = useState(null);
    const [showAirlineCountries, setShowAirlineCountries] = useState([]);

    const [countrySearchTerm, setCountrySearchTerm] = useState("");
    const [countryToAdd, setCountryToAdd] = useState("");
    const [showCountry, setShowCountry] = useState(null);
    const [showCountryAirports, setShowCountryAirports] = useState([]);

    const [airportSearchTerm, setAirportSearchTerm] = useState("");
    const [airportToAdd, setAirportToAdd] = useState("");

    useEffect(() => {
      getRequest({
        endpoint: "/getAirlines",
        options: { baseURL: BaseURL.AIChatService },
      }).then((response) => {
        setAirlines(response.data.airlineData);
        setFilteredAirlines(response.data.airlineData);
        setAllCountries(response.data.allCountries);
      });
    }, []);

    const handleAirlinesSearchChange = (event) => {
      if (!event) {
        setFilteredAirlines(airlines);
        return;
      }
      const airlineSearchTerm = event.target.value.toLowerCase();
      setAirlineSearchTerm(event.target.value);
      if (!airlineSearchTerm) {
        setFilteredAirlines(airlines);
      } else {
        setFilteredAirlines(
          airlines.filter(
            (airline) => airline.name?.toLowerCase().includes(airlineSearchTerm.toLowerCase())
          )
        );
      }
    };

    const addAirline = async () => {
      if (!file || !airlineToAdd) {
        return;
      }
      const formData = new FormData();
      formData.append("files", file);
      formData.append("airlineToAdd", airlineToAdd);
      const baseURL = process.env.REACT_APP_CHATBOT_URL;
      await axios.post(baseURL + "/addAirline", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      getRequest({
        endpoint: "/getAirlines",
        options: { baseURL: BaseURL.AIChatService },
      }).then((response) => {
        setAirlines(response.data.airlineData);
        setFilteredAirlines(response.data.airlineData);
        setAllCountries(response.data.allCountries);
      });
      setAirlineToAdd("");
      setFile("");
      fileInputRef.current.value = "";
    }

    const handleShowAirline = (airline) => {
      setShowAirline(airline);
      setShowAirlineCountries(airline.airportsByCountry.toSorted((a, b) => a.country.localeCompare(b.country)));
    }

    const handleCountrySearchChange = (event) => {
      if (!event) {
        setShowAirlineCountries(showAirline.airportsByCountry);
        return;
      }
      const countrySearchTerm = event.target.value.toLowerCase();
      setCountrySearchTerm(event.target.value);
      if (!countrySearchTerm) {
        setShowAirlineCountries(showAirline.airportsByCountry);
      } else {
        setShowAirlineCountries(
          showAirline.airportsByCountry.filter(
            (country) => country.country?.toLowerCase().includes(countrySearchTerm.toLowerCase())
          ).toSorted((a, b) => a.country.localeCompare(b.country))
        );
      }
    };

    const addCountry = () => {
      if (!countryToAdd) {
        return;
      }
      let payload = {
        target: "country",
        countryToAdd: countryToAdd,
        airline: showAirline.name,
      }
      postRequest({ endpoint: "/updateAirlineInfo", options: { baseURL: BaseURL.AIChatService }, data: payload })
      .then(() => {
        setCountryToAdd("");
        getRequest({
          endpoint: "/getAirlines",
          options: { baseURL: BaseURL.AIChatService },
        }).then((response) => {
          setAirlines(response.data.airlineData);
          setFilteredAirlines(response.data.airlineData);
          setAllCountries(response.data.allCountries);
          const updatedAirline = response.data.airlineData.find(airline => airline.name === showAirline.name);
          if (updatedAirline) {
            setShowAirline(updatedAirline);
            setShowAirlineCountries(updatedAirline.airportsByCountry.toSorted((a, b) => a.country.localeCompare(b.country)));
          }
        });
      });
    }

    const handleShowCountry = (country) => {
      setShowCountry(country);
      setShowCountryAirports(country.airports.toSorted((a, b) => a.code.localeCompare(b.code)));
    }

    const handleAirportSearchChange = (event) => {
      if (!event) {
        setShowCountryAirports(showCountry.airports.toSorted((a, b) => a.code.localeCompare(b.code)));
        return;
      }
      const airportSearchTerm = event.target.value.toLowerCase();
      setAirportSearchTerm(event.target.value);
      if (!airportSearchTerm) {
        setShowCountryAirports(showCountry.airports.toSorted((a, b) => a.code.localeCompare(b.code)));
      } else {
        setShowCountryAirports(
          showCountry.airports.filter(
            (airport) => airport.code?.toLowerCase().includes(airportSearchTerm.toLowerCase())
          ).toSorted((a, b) => a.code.localeCompare(b.code))
        );
      }
    };

    const addAirport = () => {
      if (!airportToAdd || airportToAdd.length !== 3) {
        return;
      }
      let payload = {
        target: "airport",
        action: "add",
        airportToAdd: airportToAdd,
        airline: showAirline.name,
        country: showCountry.country
      }
      postRequest({ endpoint: "/updateAirlineInfo", options: { baseURL: BaseURL.AIChatService }, data: payload })
      .then(() => {
        setAirportToAdd("");
        getRequest({
          endpoint: "/getAirlines",
          options: { baseURL: BaseURL.AIChatService },
        }).then((response) => {
          setAirlines(response.data.airlineData);
          setFilteredAirlines(response.data.airlineData);
          setAllCountries(response.data.allCountries);
          const updatedCountry = response.data.airlineData.find(airline => airline.name === showAirline.name)?.airportsByCountry?.find(country => country.country === showCountry.country);
          console.log(updatedCountry);
          if (updatedCountry) {
            console.log(updatedCountry.airports.toSorted((a, b) => a.code.localeCompare(b.code)));
            setShowCountry(updatedCountry);
            setShowCountryAirports(updatedCountry.airports.toSorted((a, b) => a.code.localeCompare(b.code)));
          }
        });
      });
    }

    const toggleActive = (code, newStatus) => {
      if (!code || newStatus === null) {
        return;
      }
      let payload = {
        target: "airport",
        action: "toggle",
        code: code,
        newStatus: newStatus,
        airline: showAirline.name,
        country: showCountry.country
      }
      postRequest({ endpoint: "/updateAirlineInfo", options: { baseURL: BaseURL.AIChatService }, data: payload })
      .then(() => {
        getRequest({
          endpoint: "/getAirlines",
          options: { baseURL: BaseURL.AIChatService },
        }).then((response) => {
          setAirlines(response.data.airlineData);
          setFilteredAirlines(response.data.airlineData);
          setAllCountries(response.data.allCountries);
          const updatedCountry = response.data.airlineData.find(airline => airline.name === showAirline.name)?.airportsByCountry?.find(country => country.country === showCountry.country);
          if (updatedCountry) {
            setShowCountry(updatedCountry);
            setShowCountryAirports(updatedCountry.airports.toSorted((a, b) => a.code.localeCompare(b.code)));
          }
        });
      });
    }

    return (
      <div className="pb-20">
        {!showAirline && !showCountry && (
          <div>
            <div className="flex items-center">
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                placeholder="Add Airline Name"
                value={airlineToAdd}
                onChange={(event) => setAirlineToAdd(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AddIcon />
                    </InputAdornment>
                  ),
                }}
                style={{ backgroundColor: "#ffffff" }}
              />
            </div>
            <div className="flex items-center pb-2 font-medium">
                <input type="file" onChange={(event) => setFile(event.target.files[0])} accept=".csv" ref={fileInputRef}></input>
                <IconButton color="primary" onClick={addAirline}>
                  <AddIcon></AddIcon>
                </IconButton>
            </div>
            <div className="flex items-center pb-5">
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                placeholder="Search Airlines"
                value={airlineSearchTerm}
                onChange={handleAirlinesSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                style={{ backgroundColor: "#ffffff" }}
              />
            </div>
            {filteredAirlines.length > 0 && (
              <div>
                <table>
                  <thead>
                    <tr>
                      <th className="border border-gray-300 px-2 py-2">Airline</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredAirlines.toSorted().map((airline) => (
                      <tr>
                        <td className="border border-gray-300 px-2 py-2">
                          <button onClick={() => handleShowAirline(airline)} className={"px-2 py-1 rounded-lg hover:text-blue-600 hover:bg-blue-50"}>{airline["name"]}</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
        {showAirline && !showCountry && (
          <div className="flex flex-col items-center font-sans">
            <h2 className="text-xl font-medium">{showAirline.name} Countries</h2>
            <button onClick={() => setShowAirline(false)} className="mt-2 px-3 py-1 rounded-md text-base font-medium hover:text-blue-500 hover:bg-blue-100 bg-blue-300">Back to All Airlines</button>
            <div className="mt-6 mb-14 flex">
              <div>
                <div className="mb-4 flex items-center">
                  <select 
                    className="border rounded appearance-none bg-white bg-no-repeat bg-right"
                    value={countryToAdd}
                    onChange={(event) => setCountryToAdd(event.target.value)}
                    style={{
                      backgroundImage: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3E%3C/svg%3E\")",
                      backgroundSize: "1.5em 1.5em"
                    }}
                  >
                    <option default value="">Select Country</option>
                    {allCountries.filter(country => !showAirlineCountries.some(airlineCountry => airlineCountry.country === country)).toSorted().map((country) => (
                      <option value={country}>{country}</option>
                    ))}
                  </select>
                  <IconButton color="primary" onClick={addCountry}>
                    <AddIcon></AddIcon>
                  </IconButton>
                </div>
                <div className="flex items-center pb-5">
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder="Search Countries"
                    value={countrySearchTerm}
                    onChange={handleCountrySearchChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    style={{ backgroundColor: "#ffffff" }}
                  />
                </div>
                <table>
                  <thead>
                    <tr>
                      <th className="border border-gray-300 px-2 py-2">Countries</th>
                    </tr>
                  </thead>
                  <tbody>
                    {showAirlineCountries.map((country) => (
                      <tr>
                        <td className="border border-gray-300 px-2 py-2">
                          <button onClick={() => handleShowCountry(country)} className={"px-2 py-1 rounded-lg hover:text-blue-600 hover:bg-blue-50"}>{country.country}</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {showAirline && showCountry && (
          <div className="flex flex-col items-center font-sans">
            <h2 className="text-xl font-medium">{showAirline.name} {showCountry.country} Airports</h2>
            <button onClick={() => setShowCountry(false)} className="mt-2 px-3 py-1 rounded-md text-base font-medium hover:text-blue-500 hover:bg-blue-100 bg-blue-300">Back to All Countries</button>
            <div className="mt-6 mb-14 flex">
              <div>
                <div className="mb-4 flex items-center">
                  <input type="text" value={airportToAdd} placeholder="Airport Code" onChange={(event) => setAirportToAdd(event.target.value)} />
                  <IconButton color="primary" onClick={addAirport}>
                    <AddIcon></AddIcon>
                  </IconButton>
                </div>
                <div className="flex items-center pb-5">
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder="Search Airports"
                    value={airportSearchTerm}
                    onChange={handleAirportSearchChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    style={{ backgroundColor: "#ffffff" }}
                  />
                </div>
                <table>
                  <thead>
                    <tr>
                      <th colSpan="2" className="border border-gray-300 px-2 py-2 font-bold text-base">Airports</th>
                    </tr>
                    <tr>
                      <th className="border border-gray-300 px-2 py-2">Code</th>
                      <th className="border border-gray-300 px-2 py-2">Active</th>
                    </tr>
                  </thead>
                  <tbody>
                    {showCountryAirports.toSorted().map((airport) => (
                      <tr>
                        <td className="border border-gray-300 px-2 py-2">
                          {airport.code}
                        </td>
                        <td className="border border-gray-300 px-2 py-2">
                          <Switch checked={airport.active} onChange={() => toggleActive(airport.code, !airport.active)}/>
                          {/* <Switch checked={airport.active} onChange={() => console.log(airport.code, !airport.active)} /> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  const BreedsRepo = () => {
    const [breeds, setBreeds] = useState([]);
    const [filteredBreeds, setFilteredBreeds] = useState(breeds);

    const [searchTerm, setSearchTerm] = useState("");

    const [showBreed, setShowBreed] = useState(null);

    const [breedToAdd, setBreedToAdd] = useState(null);
    const [breedToDelete, setBreedToDelete] = useState(null);

    const [airlineToAdd, setAirlineToAdd] = useState(null);
    const [airlineToDelete, setAirlineToDelete] = useState(null);

    const [countryToAdd, setCountryToAdd] = useState(null);
    const [countryToDelete, setCountryToDelete] = useState(null);

    const [allAirlines, setAllAirlines] = useState([]);
    const [allCountries, setAllCountries] = useState([]);

    useEffect(() => {
      getRequest({
        endpoint: "/getBreeds",
        options: { baseURL: BaseURL.AIChatService },
      }).then((response) => {
        setBreeds(response.data.breeds?.toSorted());
        setFilteredBreeds(response.data.breeds?.toSorted());
        setAllAirlines(response.data.allAirlines?.toSorted());
        setAllCountries(response.data.allCountries?.toSorted());
      });
    },[breedToAdd, breedToDelete]);

    const handleSearchChange = (event) => {
      if (!event) {
        setFilteredBreeds(breeds);
        return;
      }
      const searchTerm = event.target.value.toLowerCase();
      setSearchTerm(event.target.value);
      if (!searchTerm) {
        setFilteredBreeds(breeds);
      } else {
        setFilteredBreeds(
          breeds.filter(
            (breed) =>
              breed.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
              breed.airlinesThatAllow?.some(airline => airline.toLowerCase().includes(searchTerm.toLowerCase())) ||
              breed.countriesThatBan?.some(country => country.toLowerCase().includes(searchTerm.toLowerCase()))
          )
        );
      }
    };

    const updateBreedInfo = (target, action, value, breed) => {
      let payload = {
        target: target,
        action: action,
        value: value,
        breed: breed,
      }
      postRequest({ endpoint: "/updateBreedInfo", options: { baseURL: BaseURL.AIChatService }, data: payload })
    }

    const addBreed = () => {
      if (!breedToAdd || breeds.some(breed => breed.name === breedToAdd)) {
        return;
      }
      updateBreedInfo("breed", "add", breedToAdd);
      setBreedToAdd("");
      setShowBreed({"name": breedToAdd, "airlinesThatAllow": [], "countriesThatBan": []});
    }

    useEffect(() => {
      const deleteBreed = () => {
        if (!breedToDelete) {
          return;
        }
        updateBreedInfo("breed", "delete", breedToDelete);
        setBreedToDelete("");
      }
      deleteBreed();
    }, [breedToDelete])

    const addAirline = () => {
      if (!showBreed || !airlineToAdd) {
        return;
      }
      let airlines = showBreed["airlinesThatAllow"]
      airlines.push(airlineToAdd)
      updateBreedInfo("airline", "add", airlines, showBreed["name"]);
      setAirlineToAdd("");
    }

    useEffect(() => {
      const deleteAirline = () => {
        if (!showBreed || !airlineToDelete) {
          return;
        }
        let airlines = showBreed["airlinesThatAllow"]
        airlines.splice(airlines.indexOf(airlineToDelete), 1)
        updateBreedInfo("airline", "delete", airlines, showBreed["name"]);
        setAirlineToDelete("");
      }
      deleteAirline();
    }, [showBreed, airlineToDelete])

    const addCountry = () => {
      if (!showBreed || !countryToAdd) {
        return;
      }
      let countries = showBreed["countriesThatBan"]
      countries.push(countryToAdd)
      updateBreedInfo("country", "add", countries, showBreed["name"]);
      setCountryToAdd("");
    }

    useEffect(() => {
      const deleteCountry = () => {
        if (!showBreed || !countryToDelete) {
          return;
        }
        let countries = showBreed["countriesThatBan"]
        countries.splice(countries.indexOf(countryToDelete), 1)
        updateBreedInfo("country", "delete", countries, showBreed["name"]);
        setCountryToDelete("");
      }
      deleteCountry();
    }, [showBreed, countryToDelete])

    return (
      <div className="pb-20">
        {!showBreed && (
          <div className="flex items-center pb-5">
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              placeholder="Search Breeds"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              style={{ backgroundColor: "#ffffff" }}
            />
            <TextField
              variant="outlined"
              size="small"
              placeholder="Add Breed"
              value={breedToAdd}
              onChange={(event) => setBreedToAdd(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AddIcon />
                  </InputAdornment>
                ),
              }}
              style={{ backgroundColor: "#ffffff" }}
            />
            <IconButton color="primary" onClick={addBreed}>
              <AddIcon></AddIcon>
            </IconButton>
          </div>
        )}
        {filteredBreeds.length > 0 && !showBreed && (
          <div>
            <table>
              <thead>
                <tr>
                  <th className="border border-gray-300 px-2 py-2">Breed Name</th>
                  <th className="border border-gray-300 px-2 py-2">Airlines (Allow)</th>
                  <th className="border border-gray-300 px-2 py-2">Countries (Ban)</th>
                </tr>
              </thead>
              <tbody>
                {filteredBreeds.map((breed) => (
                  <tr>
                    <td className="border border-gray-300 px-2 py-2">
                      <button onClick={() => setShowBreed(breed)} className={"px-2 py-1 rounded-lg hover:text-blue-600 hover:bg-blue-50"}>{breed["name"]}</button>
                    </td>
                    <td className="border border-gray-300 px-2 py-2">
                      {breed["airlinesThatAllow"].toSorted().join(', ')}
                    </td>
                    <td className="border border-gray-300 px-2 py-2">
                      {breed["countriesThatBan"].toSorted().join(', ')}
                    </td>
                    <td>
                      <IconButton onClick={() => setBreedToDelete(breed["name"])}>
                        <DeleteIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {showBreed && (
          <div className="flex flex-col items-center font-sans">
            <h2 className="text-xl font-medium">{showBreed["name"]}</h2>
            <div className="mt-10 mb-14 flex">
              <div className="mr-36">
                <h3 className="text-base font-medium mb-2">Airlines That Allow</h3>
                <div className="mb-4 flex items-center">
                  <select 
                    className="mr-2 p-1 pl-3 pr-4 border rounded appearance-none bg-white bg-no-repeat bg-right"
                    value={airlineToAdd}
                    onChange={(event) => setAirlineToAdd(event.target.value)}
                    style={{
                      backgroundImage: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3E%3C/svg%3E\")",
                      backgroundSize: "1.5em 1.5em"
                    }}
                  >
                    <option default value="">Select Airline</option>
                    {allAirlines.filter(airline => !showBreed["airlinesThatAllow"].includes(airline)).toSorted().map((airline) => (
                      <option value={airline}>{airline}</option>
                    ))}
                  </select>
                  <IconButton color="primary" onClick={addAirline}>
                    <AddIcon></AddIcon>
                  </IconButton>
                </div>
                <ul className="list-none pl-0">
                  {showBreed["airlinesThatAllow"].toSorted().map((airline) => (
                    <li key={airline} className="flex justify-between items-center">
                      <span className="text-base">{airline}</span>
                      <IconButton onClick={() => setAirlineToDelete(airline)}>
                        <DeleteIcon />
                      </IconButton>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className="text-base font-medium mb-2">Countries That Ban</h3>
                <div className="mb-4 flex items-center">
                  <select 
                    className="mr-2 p-1 pl-3 pr-4 border rounded appearance-none bg-white bg-no-repeat bg-right"
                    value={countryToAdd}
                    onChange={(event) => setCountryToAdd(event.target.value)}
                    style={{
                      backgroundImage: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3E%3C/svg%3E\")",
                      backgroundSize: "1.5em 1.5em"
                    }}
                  >
                    <option default value="">Select Country</option>
                    {allCountries.filter(country => !showBreed["countriesThatBan"].includes(country)).toSorted().map((country) => (
                      <option value={country}>{country}</option>
                    ))}
                  </select>
                  <IconButton color="primary" onClick={addCountry}>
                    <AddIcon></AddIcon>
                  </IconButton>
                </div>
                <ul className="list-none pl-0">
                  {showBreed["countriesThatBan"].toSorted().map((country) => (
                    <li key={country} className="flex justify-between items-center">
                      <span className="text-base">{country}</span>
                      <IconButton onClick={() => setCountryToDelete(country)}>
                        <DeleteIcon />
                      </IconButton>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <button onClick={() => setShowBreed(false)} className="px-3 py-2 rounded-md text-base font-medium hover:text-blue-500 hover:bg-blue-100 bg-blue-300">Back to All Breeds</button>
          </div>
        )}
      </div>
    );
  }

  const RatesRepo = () => {
    const [rates, setRates] = useState([]);
    const [filteredRates, setFilteredRates] = useState(rates);
    const [allAirlines, setAllAirlines] = useState([]);

    const [airlineSearchTerm, setAirlineSearchTerm] = useState("");
    const [fromSearchTerm, setFromSearchTerm] = useState("");
    const [toSearchTerm, setToSearchTerm] = useState("");

    const [airline, setAirline] = useState(null);
    const [file, setFile] = useState();
    const fileInputRef = useRef(null);

    useEffect(() => {
      getRequest({
        endpoint: "/getRates",
        options: { baseURL: BaseURL.AIChatService },
      }).then((response) => {
        setRates(response.data.rateData);
        setFilteredRates(response.data.rateData);
        setAllAirlines(response.data.allAirlines.toSorted());
      });
    }, []);

    const handleRatesSearchChange = (event, field) => {
      if (!event || !field) {
        setFilteredRates(rates);
        return;
      }
    
      let newAirlineSearchTerm = airlineSearchTerm;
      let newFromSearchTerm = fromSearchTerm;
      let newToSearchTerm = toSearchTerm;
    
      if (field === "airline") {
        newAirlineSearchTerm = event.target.value;
        setAirlineSearchTerm(newAirlineSearchTerm);
      } else if (field === "from") {
        newFromSearchTerm = event.target.value;
        setFromSearchTerm(newFromSearchTerm);
      } else if (field === "to") {
        newToSearchTerm = event.target.value;
        setToSearchTerm(newToSearchTerm);
      }
    
      if (!newAirlineSearchTerm && !newFromSearchTerm && !newToSearchTerm) {
        setFilteredRates(rates);
      } else {
        setFilteredRates(
          rates.filter(
            (rate) =>
              rate.airline?.toLowerCase().includes(newAirlineSearchTerm.toLowerCase()) &&
              rate.fromAirport?.toLowerCase().includes(newFromSearchTerm.toLowerCase()) &&
              rate.toAirport?.toLowerCase().includes(newToSearchTerm.toLowerCase())
          )
        );
      }
    };

    const updateRates = async () => {
      if (!file || !airline) {
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("airline", airline);
      const baseURL = process.env.REACT_APP_CHATBOT_URL;
      await axios.post(baseURL + "/updateRates", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      getRequest({
        endpoint: "/getRates",
        options: { baseURL: BaseURL.AIChatService },
      }).then((response) => {
        setRates(response.data.rateData);
        setFilteredRates(response.data.rateData);
      });
      setAirline("");
      setFile("");
      fileInputRef.current.value = "";
    }

    return (
      <div className="pb-20">
        <div className="flex items-center font-medium">
          <div className="flex items-center mr-5">
            <select 
              className="mr-2 p-1 pl-3 pr-4 border rounded appearance-none bg-white bg-no-repeat bg-right"
              value={airline}
              onChange={(event) => setAirline(event.target.value)}
              style={{
                backgroundImage: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3E%3C/svg%3E\")",
                backgroundSize: "1.5em 1.5em"
              }}
            >
              <option default value="">Select Airline</option>
              {allAirlines.toSorted().map((airline) => (
                <option value={airline}>{airline}</option>
              ))}
            </select>
          </div>
          <input type="file" onChange={(event) => setFile(event.target.files[0])} accept=".csv" ref={fileInputRef}></input>
          <IconButton color="primary" onClick={updateRates}>
            <AddIcon></AddIcon>
          </IconButton>
        </div>
        <div className="flex items-center pb-5">
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search Airlines"
            value={airlineSearchTerm}
            onChange={(event) => {handleRatesSearchChange(event, "airline")}}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            style={{ backgroundColor: "#ffffff" }}
          />
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search From Airport"
            value={fromSearchTerm}
            onChange={(event) => {handleRatesSearchChange(event, "from")}}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            style={{ backgroundColor: "#ffffff" }}
          />
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search To Airport"
            value={toSearchTerm}
            onChange={(event) => {handleRatesSearchChange(event, "to")}}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            style={{ backgroundColor: "#ffffff" }}
          />
        </div>
        {filteredRates.length > 0 && (
          <div>
            <table>
              <thead>
                <tr>
                  <th className="border border-gray-300 px-2 py-2">Airline</th>
                  <th className="border border-gray-300 px-2 py-2">From</th>
                  <th className="border border-gray-300 px-2 py-2">To</th>
                  <th className="border border-gray-300 px-2 py-2">Min</th>
                  <th className="border border-gray-300 px-2 py-2">Nominal</th>
                  <th className="border border-gray-300 px-2 py-2">45+</th>
                  <th className="border border-gray-300 px-2 py-2">100+</th>
                  <th className="border border-gray-300 px-2 py-2">300+</th>
                  <th className="border border-gray-300 px-2 py-2">500+</th>
                  <th className="border border-gray-300 px-2 py-2">1000+</th>
                </tr>
              </thead>
              <tbody>
                {filteredRates.toSorted((a, b) => a.airline.localeCompare(b.airline)).slice(0, 100).map((rate) => (
                  <tr>
                    <td className="border border-gray-300 px-2 py-2">
                      {rate["airline"]}
                    </td>
                    <td className="border border-gray-300 px-2 py-2">
                      {rate["fromAirport"]}
                    </td>
                    <td className="border border-gray-300 px-2 py-2">
                      {rate["toAirport"]}
                    </td>
                    <td className="border border-gray-300 px-2 py-2">
                      ${rate["rates"]["Min"]}
                    </td>
                    <td className="border border-gray-300 px-2 py-2">
                      ${rate["rates"]["Nominal"]}
                    </td>
                    <td className="border border-gray-300 px-2 py-2">
                      ${rate["rates"]["45+"]}
                    </td>
                    <td className="border border-gray-300 px-2 py-2">
                      ${rate["rates"]["100+"]}
                    </td>
                    <td className="border border-gray-300 px-2 py-2">
                      ${rate["rates"]["300+"]}
                    </td>
                    <td className="border border-gray-300 px-2 py-2">
                      ${rate["rates"]["500+"]}
                    </td>
                    <td className="border border-gray-300 px-2 py-2">
                      ${rate["rates"]["1000+"]}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    )
  }

  const CratesRepo = () => {
    const [crates, setCrates] = useState([]);

    const [model, setModel] = useState("");
    const [size, setSize] = useState("");
    const [length, setLength] = useState();
    const [width, setWidth] = useState();
    const [height, setHeight] = useState();

    useEffect(() => {
      getRequest({
        endpoint: "/getCrates",
        options: { baseURL: BaseURL.AIChatService },
      }).then((response) => {
        setCrates(response.data.crateData);
      });
    },[size])

    const addCrate = () => {
      if (!model || !size || !length || !width || !height) {
        return;
      }
      const payload = {
        action: "add",
        model: model,
        size: size,
        ext_dim: {length: parseFloat(length), width: parseFloat(width), height: parseFloat(width)}
      }
      postRequest({ endpoint: "/updateCrates", options: { baseURL: BaseURL.AIChatService }, data: payload })
      setModel("");
      setSize("");
      setLength("");
      setWidth("");
      setHeight("");
    }

    const deleteCrate = (crateModel) => {
      if (!crateModel) {
        return;
      }
      const payload = {
        action: "delete",
        crateModel: crateModel
      }
      postRequest({ endpoint: "/updateCrates", options: { baseURL: BaseURL.AIChatService }, data: payload })
      .then(() => {
        getRequest({
          endpoint: "/getCrates",
          options: { baseURL: BaseURL.AIChatService },
        }).then((response) => {
          setCrates(response.data.crateData);
        });
      })
    }

    return (
      <div className="pb-20">
        <div className="mb-4 flex items-center">
          <input
            type="text"
            value={model}
            onChange={(event) => setModel(event.target.value)}
            placeholder="Model"
            className="mr-2 w-28 text-sm"
          />
          <select 
            className="text-sm w-33 mr-2 border rounded appearance-none bg-white bg-no-repeat bg-right"
            value={size}
            onChange={(event) => setSize(event.target.value)}
            style={{
              backgroundImage: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3E%3C/svg%3E\")",
              backgroundSize: "1.5em 1.5em"
            }}
          >
            <option default value="">Size</option>
            <option value="Small">Small</option>
            <option value="Medium">Medium</option>
            <option value="Intermediate">Intermediate</option>
            <option value="Large">Large</option>
            <option value="Extra Large">Extra Large</option>
            <option value="Giant">Giant</option>
          </select>
          <input
            type="number"
            value={length}
            onChange={(event) => setLength(event.target.value)}
            placeholder="L (in)"
            className="mr-1 w-20 text-sm"
          />
          <input
            type="number"
            value={width}
            onChange={(event) => setWidth(event.target.value)}
            placeholder="W (in)"
            className="mr-1 w-20 text-sm"
          />
          <input
            type="number"
            value={height}
            onChange={(event) => setHeight(event.target.value)}
            placeholder="H (in)"
            className="w-20 text-sm"
          />
          <IconButton color="primary" onClick={addCrate}>
            <AddIcon />
          </IconButton>
        </div>
        {crates.length > 0 && (
          <div className="text-base">
            <table>
              <thead>
                <tr>
                  <th className="border border-gray-300 px-2 py-2">Model</th>
                  <th className="border border-gray-300 px-2 py-2">Size</th>
                  <th className="border border-gray-300 px-2 py-2">L x W x H (in)</th>
                </tr>
              </thead>
              <tbody>
                {crates.map((crate) => (
                  <tr>
                    <td className="border border-gray-300 px-2 py-2">
                      {crate.model}
                    </td>
                    <td className="border border-gray-300 px-2 py-2">
                      {crate.size}
                    </td>
                    <td className="border border-gray-300 px-2 py-2">
                      {crate.ext_dim.length} x {crate.ext_dim.width} x {crate.ext_dim.height}
                    </td>
                    <IconButton color="primary" onClick={() => {deleteCrate(crate.model)}}>
                      <DeleteIcon />
                    </IconButton>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    )
  }

  const RAGRepo = () => {
    const [file, setFile] = useState(null);
    const [country, setCountry] = useState("");
    const [searchTerm, setSearchTerm] = useState("");

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [filteredUploadedFiles, setFilteredUploadedFiles] = useState(uploadedFiles);
    const [allCountries, setAllCountries] = useState([]);
    const fileInputRef = useRef(null);

    useEffect(() => {
      getRequest({
        endpoint: "/getUploadedFiles",
        options: { baseURL: BaseURL.AIChatService },
      }).then((response) => {
        setUploadedFiles(response.data.files);
        setFilteredUploadedFiles(response.data.files);
        setAllCountries(response.data.countries);
      });
    }, [file]);

    const formatDateToLocal = (timestamp) => {
      const date = new Date(timestamp);
      date.setTime(date.getTime() + 5 * 60 * 60 * 1000);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    };

    const onFileUpload = () => {
      if (!file || !country) {
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("country", country);
      const baseURL = process.env.REACT_APP_CHATBOT_URL;
      axios.post(baseURL + "/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    };

    const handleDocumentsSearchChange = (event) => {
      if (!event) {
        setFilteredUploadedFiles(uploadedFiles);
        return;
      }
      const searchTerm = event.target.value.toLowerCase();
      setSearchTerm(event.target.value);
      if (!searchTerm) {
        setFilteredUploadedFiles(uploadedFiles);
      } else {
        setFilteredUploadedFiles(
          uploadedFiles.filter(
            (file) => file.country?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            file.fileName?.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      }
    }

    return (
      <div>
        <div className="flex items-center font-medium mb-5">
          <div className="flex items-center mr-5">
            <select 
              className="border rounded appearance-none bg-white bg-no-repeat bg-right"
              value={country}
              onChange={(event) => setCountry(event.target.value)}
              style={{
                backgroundImage: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3E%3C/svg%3E\")",
                backgroundSize: "1.5em 1.5em"
              }}
            >
              <option default value="">Select Country</option>
              {allCountries.toSorted().map((country) => (
                <option value={country}>{country}</option>
              ))}
            </select>
            <input
              type="file"
              className="ml-3"
              style={{width: "200px"}}
              onChange={(event) => setFile(event.target.files[0])}
              accept="application/pdf"
              ref={fileInputRef}
            />
            <IconButton color="primary" onClick={onFileUpload}>
              <AddIcon></AddIcon>
            </IconButton>
          </div>
        </div>
        <div className="flex items-center mb-3">
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Search Country Profiles"
            value={searchTerm}
            onChange={handleDocumentsSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            style={{ backgroundColor: "#ffffff" }}
          />
        </div>
        {filteredUploadedFiles.length > 0 && (
          <table className="max-w-xl sm:max-w-sm text-left border-collapse border border-gray-300">
            <thead>
              <tr>
                <th className="border border-gray-300 px-2 py-2">Country</th>
                <th className="border border-gray-300 px-2 py-2">File Name</th>
                <th className="border border-gray-300 px-2 py-2">Size (KB)</th>
                <th className="border border-gray-300 px-2 py-2">Date</th>
              </tr>
            </thead>
            <tbody>
              {filteredUploadedFiles.toSorted().map((file, index) => (
                <tr key={index}>
                  <td className="border border-gray-300 px-2 py-2">
                    {file.country}
                  </td>
                  <td className="border border-gray-300 px-2 py-2">
                    {file.fileName}
                  </td>
                  <td className="border border-gray-300 px-2 py-2">
                    {(file.size / 1024).toFixed(2)}
                  </td>
                  <td className="border border-gray-300 px-2 py-2">
                    {formatDateToLocal(file.timestamp)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    )
  };

  return (
    <div className="flex flex-col px-1 items-center mt-3 font-sans">
      <div className="p-1 text-lg mb-3 text-gray-800">
        <nav className="flex justify-end space-x-4">
          <button onClick={() => setRepo(RepoState.IPATA_MEMBERS)} className={`px-3 py-2 rounded-md font-medium hover:text-blue-500 hover:bg-blue-100 ${repo === RepoState.IPATA_MEMBERS ? 'text-blue-700 bg-blue-300' : 'text-gray-800'}`}>{RepoState.IPATA_MEMBERS}</button>
          <button onClick={() => setRepo(RepoState.AIRLINES)} className={`px-3 py-2 rounded-md font-medium hover:text-blue-500 hover:bg-blue-100 ${repo === RepoState.AIRLINES ? 'text-blue-700 bg-blue-300' : 'text-gray-800'}`}>{RepoState.AIRLINES}</button>
          <button onClick={() => setRepo(RepoState.BANNED_BREEDS)} className={`px-3 py-2 rounded-md font-medium hover:text-blue-500 hover:bg-blue-100 ${repo === RepoState.BANNED_BREEDS ? 'text-blue-700 bg-blue-300' : 'text-gray-800'}`}>{RepoState.BANNED_BREEDS}</button>
          <button onClick={() => setRepo(RepoState.AIRLINE_RATES)} className={`px-3 py-2 rounded-md font-medium hover:text-blue-500 hover:bg-blue-100 ${repo === RepoState.AIRLINE_RATES ? 'text-blue-700 bg-blue-300' : 'text-gray-800'}`}>{RepoState.AIRLINE_RATES}</button>
          <button onClick={() => setRepo(RepoState.CRATES)} className={`px-3 py-2 rounded-md font-medium hover:text-blue-500 hover:bg-blue-100 ${repo === RepoState.CRATES ? 'text-blue-700 bg-blue-300' : 'text-gray-800'}`}>{RepoState.CRATES}</button>
          <button onClick={() => setRepo(RepoState.CHATBOT_CONFIG)} className={`px-3 py-2 rounded-md font-medium hover:text-blue-500 hover:bg-blue-100 ${repo === RepoState.CHATBOT_CONFIG ? 'text-blue-700 bg-blue-300' : 'text-gray-800'}`}>{RepoState.CHATBOT_CONFIG}</button>
          <button onClick={() => setRepo(RepoState.COUNTRY_PROFILES)} className={`px-3 py-2 rounded-md font-medium hover:text-blue-500 hover:bg-blue-100 ${repo === RepoState.COUNTRY_PROFILES ? 'text-blue-700 bg-blue-300' : 'text-gray-800'}`}>{RepoState.COUNTRY_PROFILES}</button>
        </nav>
      </div>
      <h1 className="text-2xl mb-3 text-gray-800 font-bold p-6">{repo} Repository</h1>
      {repo === RepoState.AIRLINES && <AirlinesRepo />}
      {repo === RepoState.BANNED_BREEDS && <BreedsRepo />}
      {repo === RepoState.AIRLINE_RATES && <RatesRepo />}
      {repo === RepoState.CRATES && <CratesRepo />}
      {repo === RepoState.COUNTRY_PROFILES && <RAGRepo />}
    </div>
  );
};

export default Repository;