import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getRequest, postRequest } from "apis/axios.handler";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function ExportQuoteDashboard() {
  const navigate = useNavigate();
  const [quotes, setQuotes] = useState([]);
  const [detailedView, setDetailedView] = useState(null);
  const [filter, setFilter] = useState("");
  const [info, setInfo] = useState({
    email: "",
    company: "",
    phone: "",
    customerName: "",
    departureDate: "",
    awb: "",
    ref_id: "",
  });
  const [editingRow, setEditingRow] = useState(null);

  const filteredQuotes = quotes?.filter(
    (quote) =>
      quote.name?.toLowerCase().includes(filter.toLowerCase()) ||
      quote.customer_name?.toLowerCase().includes(filter.toLowerCase()) ||
      quote.company?.toLowerCase().includes(filter.toLowerCase()) ||
      quote.email?.toLowerCase().includes(filter.toLowerCase()) ||
      quote.quoteLeadId
        ?.toString()
        .toLowerCase()
        .includes(filter.toLowerCase()) ||
      quote.routing?.from?.toLowerCase().includes(filter.toLowerCase()) ||
      quote.routing?.to?.toLowerCase().includes(filter.toLowerCase())
  );

  useEffect(() => {
    if (!editingRow) {
      getRequest({
        endpoint: "/quote/getAllExportQuotes",
      }).then((response) => {
        if (response.data) {
          setQuotes(response.data);
        }
      });
    }
  }, [editingRow]);

  const handleQuoteIdClick = (rowIndex) => {
    setDetailedView(detailedView === rowIndex ? null : rowIndex);
  };

  const handleInfoClick = (rowIndex) => {
    setEditingRow(rowIndex);
  };

  const handleConfirmOrderClick = (rowIndex) => {
    if (!filteredQuotes[rowIndex].departure_date || !filteredQuotes[rowIndex].awb) {
      alert(
        "The departure date and AWB are required before confirming the order."
      );
      return;
    }
    setEditingRow(-1);
    postRequest({
      endpoint: "/quote/confirmOrder",
      data: filteredQuotes[rowIndex],
    }).then(() => {
      // Just to update the page
      setEditingRow(null);
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleConfirmInfo = (index) => {
    postRequest({
      endpoint: "/quote/updateInfo",
      data: {
        userId: filteredQuotes[index].userId,
        quoteId: filteredQuotes[index].quoteLeadId,
        email: info.email === "" ? filteredQuotes[index].email : info.email,
        company: info.company === "" ? filteredQuotes[index].company : info.company,
        phone: info.phone === "" ? filteredQuotes[index].phone : info.phone,
        customerName:
          info.customerName === ""
            ? filteredQuotes[index].name === ""
              ? filteredQuotes[index].customer_name
              : filteredQuotes[index].name
            : info.customerName,
        departureDate:
          info.departureDate === ""
            ? filteredQuotes[index].departure_date
            : info.departureDate,
        awb: info.awb === "" ? filteredQuotes[index].awb : info.awb,
        referenceId:
          info.ref_id === "" ? filteredQuotes[index].reference_id : info.ref_id,
      },
    })
      .then(() => {
        setEditingRow(null);
        setInfo({
          customerName: "",
          departureDate: "",
          awb: "",
          ref_id: "",
        });
      })
      .catch((error) => {
        console.error(error);
        setEditingRow(null);
        setInfo({
          customerName: "",
          departureDate: "",
          awb: "",
          ref_id: "",
        });
      });
  };

  function formatPrice(number) {
    let numberStr = Math.round(number).toString();
    let formattedStr = numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedStr;
  }

  function validatePhoneInput(event) {
    const key = event.key;
    const allowedKeys = [
      "+",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
    ];
    if (!allowedKeys.includes(key)) {
      event.preventDefault();
    }
  }

  function toSentenceCase(str) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  function formatDate(date) {
    if (!date) return;
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = months[parseInt(date.substring(5, 7), 10) - 1];
    const day = date.substring(8, 10);
    return `${month}-${String(day).padStart(2, "0")}`;
  }

  function formatName(fullName) {
    let nameParts = fullName?.trim().split(" ");

    if (nameParts?.length > 1) {
      let firstName = nameParts[0];
      let lastName = nameParts.slice(1).join(" ");
      return `${lastName} ${firstName[0]}.`;
    } else {
      return fullName;
    }
  }

  return (
    <>
      <div className="flex flex-col items-center">
        <h1 className="text-xl font-bold text-gray-700 flex-grow text-center">
          Export Quotes Provided
        </h1>
      </div>
      <button
        onClick={() =>
          navigate("/rfq", {
            state: {
              customerType: "business",
              source: "admin",
            },
          })
        }
        className="mt-2 bg-blurmine-100 text-stone-50 px-4 py-2 rounded"
      >
        B2B RFQ
      </button>
      <button
        onClick={() =>
          navigate("/rfq", {
            state: {
              customerType: "customer",
              source: "admin",
            },
          })
        }
        className="mt-2 ml-2 bg-blurmine-100 text-stone-50 px-4 py-2 rounded"
      >
        B2C RFQ
      </button>
      <div className="mt-4">
        <input
          type="text"
          placeholder="Search"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="px-2 py-1 border rounded w-full"
        />
      </div>
      <div className="overflow-x-auto mt-4">
        <table className="min-w-full divide-y divide-gray-200 mt-4">
          <thead>
            <tr>
              <th className="px-1 py-3 bg-gray-50 text-left text-s font-large text-gray-800 tracking-wider sm:hidden">
                Email
              </th>
              <th className="px-1 py-3 bg-gray-50 text-left text-s font-large text-gray-800 tracking-wider">
                QuoteID
              </th>
              <th className="px-1 py-3 bg-gray-50 text-left text-s font-large text-gray-800 tracking-wider">
                Company
              </th>
              <th className="px-1 py-3 bg-gray-50 text-left text-s font-large text-gray-800 tracking-wider sm:hidden">
                Phone
              </th>
              <th className="px-1 py-3 bg-gray-50 text-left text-s font-large text-gray-800 tracking-wider">
                Name
              </th>
              <th className="px-1 py-3 bg-gray-50 text-left text-s font-large text-gray-800 tracking-wider sm:hidden">
                DEP
              </th>
              <th className="px-1 py-3 bg-gray-50 text-left text-s font-large text-gray-800 tracking-wider sm:hidden">
                ARR
              </th>
              <th className="px-1 py-3 bg-gray-50 text-left text-s font-large text-gray-800 tracking-wider sm:hidden">
                DEPDt
              </th>
              <th className="px-1 py-3 bg-gray-50 text-left text-s font-large text-gray-800 tracking-wider">
                Price
              </th>
              <th className="px-1 py-3 bg-gray-50 text-left text-s font-large text-gray-800 tracking-wider sm:hidden">
                Airline
              </th>
              <th className="px-1 py-3 bg-gray-50 text-left text-s font-large text-gray-800 tracking-wider sm:hidden">
                MyRef
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredQuotes.map((quote, index) => (
              <React.Fragment key={index}>
                <tr key={index} className="align-baseline">
                  <td className="px-1 py-4 whitespace-normal break-words sm:hidden">
                    {quote.email}
                  </td>
                  <td
                    className="px-1 py-4 whitespace-normal break-words text-blue-500 cursor-pointer"
                    onClick={() => handleQuoteIdClick(index)}
                  >
                    X{quote.userId}-{quote.quoteLeadId}
                  </td>
                  <td className="px-1 py-4 whitespace-normal break-words">
                    {quote.company}
                  </td>
                  <td className="px-1 py-4 whitespace-normal break-words">
                    {quote.phone}
                  </td>
                  <td className="px-1 py-4 whitespace-normal break-words">
                    {formatName(
                      quote.name === "" ? quote.customer_name : quote.name
                    )}
                  </td>
                  <td className="px-1 py-4 whitespace-normal break-words sm:hidden">
                    {quote.routing.from}
                  </td>
                  <td className="px-1 py-4 whitespace-normal break-words sm:hidden">
                    {quote.routing.to}
                  </td>
                  <td className="px-1 py-4 whitespace-normal break-words sm:hidden">
                    {formatDate(quote.departure_date)}
                  </td>
                  <td className="px-1 py-4 whitespace-normal break-words">
                    ${formatPrice(quote.categories.total.price)}
                  </td>
                  <td className="px-1 py-4 whitespace-normal break-words sm:hidden sm:hidden">
                    {quote.awb?.substring(0, 3)}
                  </td>
                  <td className="px-1 py-4 whitespace-normal break-words sm:hidden sm:hidden">
                    {quote.reference_id?.substring(0, 6)}
                  </td>
                  <td className="px-1 py-4 whitespace-normal break-words">
                    {editingRow === index ? (
                      <div className="w-48">
                        <input
                          type="text"
                          name="email"
                          placeholder="Email"
                          value={info.email}
                          onChange={handleInputChange}
                          className="mb-2 px-1 py-1 border-transparent rounded w-full"
                        />
                        <input
                          type="text"
                          name="company"
                          placeholder="Company"
                          value={info.company}
                          onChange={handleInputChange}
                          className="mb-2 px-1 py-1 border-transparent rounded w-full"
                        />
                        <input
                          type="tel"
                          name="phone"
                          placeholder="Mobile Number"
                          value={info.phone}
                          onChange={handleInputChange}
                          className="mb-2 px-1 py-1 border-transparent rounded w-full focus:border-transparent"
                          onKeyPress={validatePhoneInput}
                        />
                        {quote.type === "business" && (
                          <input
                            type="text"
                            name="customerName"
                            placeholder="Customer Name"
                            value={info.customerName}
                            onChange={handleInputChange}
                            className="mb-2 px-1 py-1 border-transparent rounded w-full"
                          />
                        )}
                        <div className="my-2">
                          <ReactDatePicker
                            type="date"
                            name="departureDate"
                            placeholderText=" Departure Date"
                            selected={info.departureDate}
                            minDate={new Date()}
                            onChange={(date) => {
                              setInfo((prevInfo) => ({
                                ...prevInfo,
                                departureDate: date,
                              }));
                            }}
                            className="mb-2 rounded w-full"
                          />
                        </div>
                        <input
                          type="text"
                          name="awb"
                          placeholder="AWB"
                          value={info.awb}
                          onChange={handleInputChange}
                          className="mb-2 px-1 border-transparent rounded w-full"
                        />
                        <input
                          type="text"
                          name="ref_id"
                          placeholder="Your Reference Id"
                          value={info.ref_id}
                          onChange={handleInputChange}
                          className="mb-2 px-1 py-1 border-transparent rounded w-full"
                        />
                        <button
                          onClick={() => handleConfirmInfo(index)}
                          className="bg-transparent text-gray-700 border-2 border-blurmine-100 hover:bg-gray-200 px-4 py-2 rounded"
                        >
                          Submit
                        </button>
                        <button
                          onClick={() => setEditingRow(null)}
                          className="ml-2 bg-transparent text-gray-700 border-2 border-blurmine-100 hover:bg-gray-200 px-4 py-2 rounded"
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={() => handleInfoClick(index)}
                        className="bg-blurmine-100 text-stone-50 px-4 py-2 rounded"
                      >
                        Update
                      </button>
                    )}
                  </td>
                  <td>
                    { (quote.status === "booked") ? (
                      <button className="bg-gray-400 text-stone-50 px-2 py-2 rounded cursor-not-allowed">
                        Booked
                      </button>
                    ) : (quote.type === "customer" &&
                      (quote.userStatus === "order" ||
                        quote.userStatus === "shipped")) ? <button className="bg-red-300 text-stone-50 px-4 py-2 rounded cursor-not-allowed">
                        Book
                      </button> : (
                      <button
                        onClick={() => handleConfirmOrderClick(index)}
                        className="bg-blurmine-100 text-stone-50 px-4 py-2 rounded"
                      >
                        Book
                      </button>
                    )}
                  </td>
                </tr>
                {detailedView === index && (
                  <tr>
                    <td
                      colSpan="8"
                      className="px-2 py-4 bg-gray-50 border-t border-gray-200"
                    >
                      <div className="text-sm">
                        <div className="grid grid-cols-2 sm:grid-cols-1 gap-4">
                          <div>
                            <p className="font-medium p-1">
                              <strong>Quote ID:</strong> X{quote.userId}-
                              {quote.quoteLeadId}
                            </p>
                            <p className="font-medium p-1">
                              <strong>Departure Airport:</strong>{" "}
                              {quote.routing.from}
                            </p>
                            <p className="font-medium p-1">
                              <strong>Arrival Airport:</strong>{" "}
                              {quote.routing.to}
                            </p>
                            <p className="font-medium p-1">
                              <strong>Price:</strong> $
                              {formatPrice(quote.categories.total.price)}
                            </p>
                            <p className="font-medium p-1">
                              <strong>AWB:</strong> {quote.awb}
                            </p>
                          </div>
                          <div>
                            <p className="font-medium p-1">
                              <strong>Customer Name:</strong>{" "}
                              {quote.name === ""
                                ? quote.customer_name
                                : quote.name}
                            </p>
                            <p className="font-medium p-1">
                              <strong>Customer Email:</strong> {quote.email}
                            </p>
                            <p className="font-medium p-1">
                              <strong>Phone Number:</strong> {quote.phone}
                            </p>
                            <p className="font-medium p-1">
                              <strong>Quote Generated on:</strong>{" "}
                              {moment(quote.timestamp).format("DD-MMM-YYYY")}
                            </p>
                            <p className="font-medium p-1">
                              <strong>Reference Id:</strong>{" "}
                              {quote.reference_id}
                            </p>
                          </div>
                        </div>
                        <div className="mt-4 border-t border-gray-200 pt-4">
                          <p className="font-medium p-1">
                            <strong>Categories included in Quote:</strong>
                          </p>

                          {Object.keys(quote.categories).map((category) => {
                            if (
                              quote.categories[category].price &&
                              category !== "total"
                            ) {
                              return (
                                <p className="font-medium p-1">
                                  {toSentenceCase(category)}
                                </p>
                              );
                            } else {
                              return;
                            }
                          })}
                        </div>
                        <div className="mt-4 border-t border-gray-200 pt-4">
                          <h3 className="text-lg font-medium text-gray-900">
                            <strong>Comments:</strong>
                          </h3>
                          <div className="grid grid-cols-1 sm:grid-cols-1 gap-4 mt-2">
                            <p className="whitespace-pre-wrap">
                              {quote.comments.length === 0
                                ? "-"
                                : quote.comments}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default ExportQuoteDashboard;
