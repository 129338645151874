import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import SearchIcon from "@material-ui/icons/Search";
import Avatar from "@mui/material/Avatar";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import DoNotDisturbOutlinedIcon from "@mui/icons-material/DoNotDisturbOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { getPusherInstance } from "PusherService";
import PetShippingForm from "./MakeQuote";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import PetsIcon from "@mui/icons-material/Pets"; // Example icon
import AdbIcon from "@mui/icons-material/Adb"; // Represents AI/Bot
import AiChatBot from "./AiChatBot";
import { TextareaAutosize } from "@mui/material";
import { fetchChatHistory, getUsersAPI, updateMessages } from "apis/inbox.apis";
import { sortUsersByLastMessage } from "utils/inbox.utils";
import { changeUserStatus } from "apis/inbox.apis";
import moment from "moment";
import { getRequest, BaseURL } from "apis/axios.handler";

const useStyles = makeStyles((theme) => ({
  selectedListItem: {
    backgroundColor: "#ababab",
  },
  messageContainer: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  petDetailsContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  keyText: {
    fontWeight: "bold",
  },
  blueText: {
    color: "#0000FF", // Blue color for Name, Phone, and Email
  },
  greenBoldText: {
    fontWeight: "bold",
    color: "#008000", // Green color for Pet 1 Details
  },
  secondaryText: {
    display: "block",
    color: theme.palette.text.secondary,
    fontSize: "0.875rem", // You can adjust the font size as needed
  },
  secondaryEmail: {
    display: "block",
    color: theme.palette.text.secondary,
    fontSize: "0.875rem", // You can adjust the font size as needed
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const App = ({ isCustomerInbox }) => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [chats, setChats] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(users[0]?.id);
  const [selectedUserEmail, setSelectedUserEmail] = useState(users[0]?.email);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [messages, setMessages] = useState({});
  const [agentMessages, setAgentMessages] = useState([]);
  const messagesEndRef = useRef(null);
  const messages2EndRef = useRef(null);
  const [showChat, setShowChat] = useState(true);
  const [sidebarWidth, setSidebarWidth] = useState(21); // Initial width as a percentage
  const [componentWidth, setComponentWidth] = useState(40); // Initial width as a percentage
  const [view, setView] = useState("petShipping");
  const [showPetShippingForm, setShowPetShippingForm] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [filteredCount, setFilteredCount] = useState(users.length);
  const [statusButtonClicked, setStatusButtonClicked] = useState(-1);

  const handleChatChange = (e) => {
    setSelectedChat(e.target.value);
  };

  const VIEWS = {
    CHAT: "chat",
    PET_SHIPPING: "petShipping",
    QUOTE: "quote",
    // You can add more views here
  };
  const [currentView, setCurrentView] = useState(VIEWS.CHAT);
  const [selectedOption, setSelectedOption] = useState("");
  const [totalPrice, setTotalPrice] = useState("0.00");
  const handleTotalPriceChange = (newTotalPrice) => {
    setTotalPrice(newTotalPrice);
  };

  // Handle selection change
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const changeView = (view) => {
    setCurrentView(view);
  };

  const togglePetShippingForm = () => {
    setShowPetShippingForm(!showPetShippingForm);
  };

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };

  const scrollToBottom2 = () => {
    if (messages2EndRef.current) {
      messages2EndRef.current.scrollTop = messages2EndRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (isCustomerInbox) {
          let usersData = await getUsersAPI();
          usersData = sortUsersByLastMessage(usersData, messages);
          setUsers(usersData);
          setFilteredUsers(usersData);
          setFilteredCount(usersData.length);
        } else {
          getRequest({
            endpoint: "/agent/getAgents",
          })
            .then((response) => {
              setUsers(response.data);
              setFilteredUsers(response.data);
              setFilteredCount(response.data.length);
            })
            .catch(() => {
              console.log("Error while fetching agents.");
            });
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [messages, isCustomerInbox, statusButtonClicked]); // Dependency array is empty to only run this effect on mount.

  useEffect(() => {
    // Initialize Pusher and subscribe to the 'chat' channel
    const pusher = getPusherInstance();

    const channel = pusher.subscribe("chat");
    channel.bind("message", (data) => {
      console.log("Received data:", data); // Debugging: log the received data

      const newMessage = {
        id: Date.now(),
        sender: data.sender,
        text: data.text,
        timestamp: Date.now(),
      };

      setMessages((prevMessages) => {
        const updatedMessages = {
          ...prevMessages,
          [data.userId]: [...(prevMessages[data.userId] || []), newMessage],
        };

        // Update the lastMessageTimestamp for the user who received the message
        setUsers((prevUsers) => {
          const updatedUsers = prevUsers.map((user) => {
            if (user.id === data.userId) {
              return { ...user, lastMessageTimestamp: newMessage.timestamp };
            }
            return user;
          });

          // Sort and update the filtered users right here
          setFilteredUsers(sortUsersByLastMessage(updatedUsers));

          return updatedUsers;
        });

        return updatedMessages;
      });
    });

    return () => {
      channel.unbind("message");
      pusher.unsubscribe("chat");
    };
  }, []); // The dependency array should be empty to avoid re-subscribing to Pusher on each render.

  const handleListItemClick = (user) => {
    if (isCustomerInbox) {
      setSelectedUserId(user.id);
    } else {
      setSelectedUserId(user._id);
    }
    setSelectedUserEmail(user.email);
    setSelectedUser(user);
    setShowChat(false);
    setFilterButtonClicked(-1);
  };

  const handleSendMessage = () => {
    if (newMessage.trim() === "") return;

    const currentMessageText = newMessage;
    setNewMessage("");

    const messagePayload = {
      id: Date.now(),
      text: currentMessageText,
      sender: "admin",
      userId: selectedUserId,
    };

    // Don't update UI immediately here if you're getting the message from Pusher

    updateMessages(messagePayload);
  };

  const handleSearchChange = (event) => {
    if (!event) {
      setFilteredUsers(users);
      setFilteredCount(users.length);
      return;
    }
    const searchTerm = event.target.value.toLowerCase(); // Convert search term to lowercase for case-insensitive comparison
    setSearchTerm(event.target.value);

    if (!searchTerm) {
      setFilteredUsers(users);
      setFilteredCount(users.length);
    } else {
      setFilteredUsers(
        users.filter(
          (user) =>
            user.name?.toLowerCase().includes(searchTerm) ||
            user.email?.toLowerCase().includes(searchTerm) ||
            user.rfqs[0]?.departureDate?.toString().includes(searchTerm) ||
            (user.lead &&
              user.lead?.toString().toLowerCase().includes(searchTerm))
        )
      );
      setFilteredCount(
        users.filter(
          (user) =>
            user.name.toLowerCase().includes(searchTerm) ||
            user.email.toLowerCase().includes(searchTerm) ||
            user.rfqs[0].departureDate.toString().includes(searchTerm) ||
            (user.lead &&
              user.lead.toString().toLowerCase().includes(searchTerm))
        ).length
      );
    }
  };

  useEffect(() => {
    if (!isCustomerInbox) {
      if (selectedUserId) {
        const token = process.env.REACT_APP_AGENT_VERIFICATION_KEY;
        getRequest({
          endpoint: "/getImportChatbotHistory",
          params: {
            agentId: selectedUserId.toString(),
            token: token,
            conversationId: selectedChat,
          },
          options: { baseURL: BaseURL.AIChatService },
        })
          .then((response) => {
            if (response.data.data === undefined) return;
            setAgentMessages(response.data.data);
          })
          .catch(() => {
            alert(
              "There is some issue with loading chat history. Please try again later."
            );
          });
      }
    }
  }, [selectedChat, selectedUserId]);

  useEffect(() => {
    if (isCustomerInbox) {
      if (selectedUserId) {
        fetchChatHistory(selectedUserId).then((chatHistory) => {
          setMessages((prevMessages) => ({
            ...prevMessages,
            [selectedUserId]: chatHistory,
          }));
        });
      }
    } else {
      if (selectedUserId) {
        const token = process.env.REACT_APP_AGENT_VERIFICATION_KEY;
        getRequest({
          endpoint: "/getImportChats",
          params: { agentId: selectedUserId.toString(), token: token },
          options: { baseURL: BaseURL.AIChatService },
        }).then((response) => {
          setChats(response.data.data);
          if (response.data.data && response.data.data.length > 0)
            setSelectedChat(response.data.data[0].conversation_id);
          else setSelectedChat(0);
        });
      }
    }
  }, [selectedUserId, isCustomerInbox]); // Depend on selectedUserId to refetch when it changes

  useLayoutEffect(() => {
    scrollToBottom();
  }, [messages[selectedUserId]]);

  useLayoutEffect(() => {
    scrollToBottom2();
  }, [messages[selectedUserId]]);

  const handleCloseChat = () => {
    setShowChat(true);
  };

  const startResizing = (mouseDownEvent) => {
    // Prevent text selection while dragging
    mouseDownEvent.preventDefault();

    const startWidth = sidebarWidth;
    const startX = mouseDownEvent.pageX;

    const handleMouseMove = (mouseMoveEvent) => {
      const currentX = mouseMoveEvent.pageX;
      const diffInPx = currentX - startX;
      const diffInPercent = (diffInPx / window.innerWidth) * 100;
      setSidebarWidth(Math.max(10, startWidth + diffInPercent)); // Adjust this for min/max width
    };

    const handleMouseUp = () => {
      // Remove these listeners once dragging is complete
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    // Add mousemove and mouseup listeners to the document
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const startResizing2 = (mouseDownEvent) => {
    mouseDownEvent.preventDefault();

    const startX = mouseDownEvent.clientX;
    const startWidthPercent = componentWidth; // This assumes componentWidth is in percent

    const handleMouseMove = (mouseMoveEvent) => {
      const currentX = mouseMoveEvent.clientX;
      const diffInPx = currentX - startX;
      const diffInPercent = (diffInPx / window.innerWidth) * 100;

      // Adjusting calculation here: you might need to invert the operation
      // If dragging right decreases width, and left increases, you might need to subtract the diff from the start width
      const newWidth = Math.max(
        10,
        Math.min(90, startWidthPercent - diffInPercent)
      ); // Inverting the operation by subtracting

      setComponentWidth(newWidth);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleStatusChange = (email, event) => {
    setStatusButtonClicked(parseInt(event.currentTarget.id));
    changeUserStatus(email, parseInt(event.currentTarget.id));
    setTimeout(() => {
      setStatusButtonClicked(-1);
    }, 1000);
    setFilteredUsers([]);
    setFilteredUsers(users);
  };

  function mapStatusIdToName(statusId) {
    switch (statusId) {
      case 0:
        return "lead";
      case 1:
        return "estimate";
      case 2:
        return "order";
      case 3:
        return "shipped";
      case 4:
        return "no action";
      default:
        return "lead";
    }
  }

  const [filterButtonClicked, setFilterButtonClicked] = useState(-1);

  const handleFilterChange = (event) => {
    if (filterButtonClicked === parseInt(event.currentTarget.id)) {
      setFilterButtonClicked(-1);
      setFilteredUsers(users);
      setFilteredCount(users.length);
      return;
    }
    setFilterButtonClicked(parseInt(event.currentTarget.id));
    setFilteredUsers(
      users.filter(
        (user) =>
          user.status === mapStatusIdToName(parseInt(event.currentTarget.id))
      )
    );
    setFilteredCount(
      users.filter(
        (user) =>
          user.status === mapStatusIdToName(parseInt(event.currentTarget.id))
      ).length
    );
  };

  return (
    <div className="flex h-[90vh] bg-gray-100">
      {isCustomerInbox && (
        <Box className="flex flex-col items-center p-4 z-30 right-4 top-[1px] absolute sm:hidden">
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleViewChange}
            aria-label="View toggle"
            sx={{
              "& .MuiToggleButton-root": { height: 30 },
              marginLeft: "10px",
            }}
          >
            <ToggleButton value="petShipping" aria-label="Pet Shipping">
              <PetsIcon />
            </ToggleButton>
            <ToggleButton value="anotherView" aria-label="Another View">
              <AdbIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}
      <div
        className={`bg-white overflow-auto sm:hidden`}
        style={{ width: `${sidebarWidth}%` }}
      >
        <div className="p-2">
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            style={{ backgroundColor: "#ffffff" }}
          />
        </div>
        {isCustomerInbox && (
          <div className="flex flex-col">
            <span className="pl-4 text-base">Count: {filteredCount}</span>
            <span className="flex justify-between space-x-0">
              <IconButton id="0" onClick={handleFilterChange}>
                <Avatar
                  sx={{
                    bgcolor:
                      filterButtonClicked === 0 ? "primary.dark" : "#e3e6e5",
                    height: 48,
                    width: 48,
                  }}
                >
                  <ArrowCircleRightOutlinedIcon
                    style={{ height: 36, width: 36 }}
                  />
                </Avatar>
              </IconButton>
              <IconButton id="1" onClick={handleFilterChange}>
                <Avatar
                  sx={{
                    bgcolor:
                      filterButtonClicked === 1 ? "primary.dark" : "#e3e6e5",
                    height: 48,
                    width: 48,
                  }}
                >
                  <RequestQuoteOutlinedIcon style={{ height: 36, width: 36 }} />
                </Avatar>
              </IconButton>
              <IconButton id="2" onClick={handleFilterChange}>
                <Avatar
                  sx={{
                    bgcolor:
                      filterButtonClicked === 2 ? "primary.dark" : "#e3e6e5",
                    height: 48,
                    width: 48,
                  }}
                >
                  <ShoppingCartCheckoutOutlinedIcon
                    style={{ height: 36, width: 36 }}
                  />
                </Avatar>
              </IconButton>
              <IconButton id="3" onClick={handleFilterChange}>
                <Avatar
                  sx={{
                    bgcolor:
                      filterButtonClicked === 3 ? "primary.dark" : "#e3e6e5",
                    height: 48,
                    width: 48,
                  }}
                >
                  <LocalShippingOutlinedIcon
                    style={{ height: 36, width: 36 }}
                  />
                </Avatar>
              </IconButton>
              <IconButton id="4" onClick={handleFilterChange}>
                <Avatar
                  sx={{
                    bgcolor:
                      filterButtonClicked === 4 ? "primary.dark" : "#e3e6e5",
                    height: 48,
                    width: 48,
                  }}
                >
                  <DoNotDisturbOutlinedIcon style={{ height: 36, width: 36 }} />
                </Avatar>
              </IconButton>
            </span>
          </div>
        )}
        <List>
          {filteredUsers.map((user) => (
            <React.Fragment>
              <ListItem
                button
                className={`border-b border-gray-200 hover:bg-blue-100 ${
                  isCustomerInbox
                    ? selectedUserId === user.id
                      ? classes.selectedListItem
                      : ""
                    : selectedUserId === user._id
                    ? classes.selectedListItem
                    : ""
                }`}
                onClick={() => handleListItemClick(user)}
              >
                <ListItemIcon>
                  {user.status === "lead" && (
                    <ArrowCircleRightOutlinedIcon
                      style={{ height: 36, width: 36 }}
                    />
                  )}
                  {user.status === "estimate" && (
                    <RequestQuoteOutlinedIcon
                      style={{ height: 36, width: 36 }}
                    />
                  )}
                  {user.status === "order" && (
                    <ShoppingCartCheckoutOutlinedIcon
                      style={{ height: 36, width: 36 }}
                    />
                  )}
                  {user.status === "shipped" && (
                    <LocalShippingOutlinedIcon
                      style={{ height: 36, width: 36 }}
                    />
                  )}
                  {user.status === "no action" && (
                    <DoNotDisturbOutlinedIcon
                      style={{ height: 36, width: 36 }}
                    />
                  )}
                </ListItemIcon>
                <React.Fragment>
                  <div className="flex flex-col w-full">
                    <div className="flex flex-rol justify-between">
                      <span className="text-base">
                        {!isCustomerInbox ? user.company : user.type === "business"
                          ? user.company
                          : user.name}{" "}
                      </span>
                      {isCustomerInbox && (
                        <span className="text-xs">
                          {Math.abs(
                            moment
                              .duration(
                                moment(user.lastMessageTimestamp).diff(
                                  moment.now()
                                )
                              )
                              .asHours()
                          ) > 24
                            ? moment(user.lastMessageTimestamp).fromNow()
                            : moment(user.lastMessageTimestamp).format(
                                "hh:mm A"
                              )}
                        </span>
                      )}
                    </div>
                    <div className="">
                      {isCustomerInbox ? (
                        <span className={classes.secondaryText}>
                          {user.lead} | {user.email}
                        </span>
                      ) : (
                        <span className={classes.secondaryText}>
                          {user.email}
                        </span>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </div>
      <div
        className="cursor-col-resize  w-1"
        onMouseDown={startResizing}
        style={{ userSelect: "none" }}
      ></div>

      <div className="flex-grow flex flex-col border-l border-R border-gray-300 sm:hidden ">
        {selectedUser && (
          <div className="flex flex-row items-center justify-between pr-10 relative top-0 w-full p-4 bg-white-A700 shadow-lg">
            <div className="text-lg">
              {!isCustomerInbox ? selectedUser?.company : selectedUser?.type === "business"
                ? selectedUser?.company
                : selectedUser?.name}{" "}
              ({selectedUser?.email})
            </div>
            {chats?.length > 0 ? (
              <div>
                <select
                  value={selectedChat}
                  onChange={handleChatChange}
                  className="mt-1 bg-gray-200 border border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  {chats?.map((chat) => (
                    <option
                      key={chat.conversation_id}
                      value={chat.conversation_id}
                    >
                      Chat {chat.conversation_id + 1} | &nbsp;
                      {Math.abs(
                        moment
                          .duration(
                            moment(chat.last_message_timestamp.$date).diff(
                              moment.now()
                            )
                          )
                          .asHours()
                      ) > 24
                        ? moment(chat.last_message_timestamp.$date).fromNow()
                        : moment(chat.last_message_timestamp.$date).format(
                            "hh:mm A"
                          )}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              ""
            )}
            {isCustomerInbox && (
              <div className="flex justify-between">
                <IconButton
                  id="0"
                  color={statusButtonClicked === 0 ? "primary" : "default"}
                  onClick={(event) =>
                    handleStatusChange(selectedUser.email, event)
                  }
                  disabled={selectedUser.status === "order" || selectedUser.status === "shipped"}
                >
                  <ArrowCircleRightOutlinedIcon
                    style={{ height: 36, width: 36 }}
                  />
                </IconButton>
                <IconButton
                  id="1"
                  color={statusButtonClicked === 1 ? "primary" : "default"}
                  onClick={(event) =>
                    handleStatusChange(selectedUser.email, event)
                  }
                  disabled={selectedUser.status === "order" || selectedUser.status === "shipped"}
                >
                  <RequestQuoteOutlinedIcon style={{ height: 36, width: 36 }} />
                </IconButton>
                <IconButton
                  id="2"
                  color={statusButtonClicked === 2 ? "primary" : "default"}
                  onClick={(event) =>
                    handleStatusChange(selectedUser.email, event)
                  }
                  disabled={selectedUser.status === "order" || selectedUser.status === "shipped"}
                >
                  <ShoppingCartCheckoutOutlinedIcon
                    style={{ height: 36, width: 36 }}
                  />
                </IconButton>
                <IconButton
                  id="3"
                  color={statusButtonClicked === 3 ? "primary" : "default"}
                  onClick={(event) =>
                    handleStatusChange(selectedUser.email, event)
                  }
                  disabled={selectedUser.status === "shipped"}
                >
                  <LocalShippingOutlinedIcon
                    style={{ height: 36, width: 36 }}
                  />
                </IconButton>
                <IconButton
                  id="4"
                  color={statusButtonClicked === 4 ? "primary" : "default"}
                  onClick={(event) =>
                    handleStatusChange(selectedUser.email, event)
                  }
                  disabled={selectedUser.status === "order" || selectedUser.status === "shipped"}
                >
                  <DoNotDisturbOutlinedIcon style={{ height: 36, width: 36 }} />
                </IconButton>
              </div>
            )}
          </div>
        )}
        <div
          className="flex-grow overflow-auto p-4 space-y-4"
          ref={messages2EndRef}
        >
          {isCustomerInbox &&
            messages[selectedUserId]?.map((message) => (
              <div
                key={message.id}
                className={`flex ${
                  message.sender === "admin" ? "justify-end" : "justify-start"
                }`}
              >
                <div
                  className={`rounded px-4 py-2 border border-gray-300 ${
                    message.sender === "admin"
                      ? "bg-gray-1900"
                      : "bg-white-A700"
                  } w-auto max-w-xs break-words rounded-xl`}
                >
                  {message.text.split("\n").map((line, lineIndex) => {
                    // Find the key in the line of text
                    const keyMatch = line.match(/(.*?):\s*(.*)/);
                    let keyStyleClass = classes.keyText;
                    let valueStyleClass = "";

                    if (keyMatch) {
                      const key = keyMatch[1].toLowerCase();
                      switch (key) {
                        case "name":
                        case "phone":
                        case "email":
                          keyStyleClass += ` ${classes.blueText}`;
                          break;
                        case "pet 1 details":
                        case "pet 2 details":
                        case "pet 3 details":
                        case "pet 4 details":
                        case "pet 5 details":
                          keyStyleClass += ` ${classes.greenBoldText}`;
                          break;
                        default:
                          break;
                      }
                    }

                    return (
                      <Box key={lineIndex} my={1}>
                        {keyMatch ? (
                          <>
                            <Typography
                              component="span"
                              className={keyStyleClass}
                            >
                              {keyMatch[1]}:
                            </Typography>{" "}
                            <Typography
                              component="span"
                              className={valueStyleClass}
                            >
                              {keyMatch[2]}
                            </Typography>
                          </>
                        ) : (
                          <Typography component="span">{line}</Typography>
                        )}
                      </Box>
                    );
                  })}
                  <div className="w-full text-right">
                    {Math.abs(
                      moment
                        .duration(moment(message.timestamp).diff(moment.now()))
                        .asHours()
                    ) > 24
                      ? moment(message.timestamp).fromNow()
                      : moment(message.timestamp).format("hh:mm A")}
                  </div>
                </div>
              </div>
            ))}
          {!isCustomerInbox &&
            (agentMessages?.length > 0 ? (
              agentMessages?.map((msg, index) => {
                return (
                  <div
                    key={index}
                    className={`p-2 rounded-md mb-2 border border-gray-300 flex flex-row justify-between ${
                      msg.role === "user"
                        ? "bg-white-A700 self-start mr-10"
                        : "bg-gray-1900 self-end ml-10"
                    }`}
                  >
                    <p className="text-sm whitespace-pre-wrap">{msg.content}</p>
                    <p className="text-xs pl-2 self-end">
                      {moment(msg.timestamp).format("HH:mm")}
                    </p>
                  </div>
                );
              })
            ) : (
              <div className="p-4 text-xl font-bold object-center">
                No Conversation
              </div>
            ))}
        </div>

        {/* Message input container */}
        {isCustomerInbox && (
          <div className="m-4 flex-none">
            <div className="flex justify-center items-center rounded-lg border">
              <TextField
                fullWidth
                placeholder="Type message here"
                variant="outlined"
                size="medium"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
                multiline
                color="primary"
                style={{ backgroundColor: "#ffffff" }}
                InputProps={{
                  inputComponent: TextareaAutosize,
                  inputProps: {
                    minRows: 1,
                    maxRows: 4,
                    style: { resize: "none", color: "#000000" },
                  },
                }}
              />
              <IconButton
                color="primary"
                component="span"
                onClick={handleSendMessage}
              >
                <SendIcon />
              </IconButton>
            </div>
          </div>
        )}
      </div>
      {isCustomerInbox && (
        <div
          className="relative flex flex-col border-l border-r border-gray-300 sm:hidden"
          style={{ width: `${componentWidth}%` }}
        >
          {view === "petShipping" ? (
            <PetShippingForm
              onTotalPriceChange={handleTotalPriceChange}
              selectedUserId={selectedUserId}
              selectedUserEmail={selectedUserEmail}
            />
          ) : (
            <AiChatBot key={selectedUserId} clientId={selectedUserId} />
          )}
          <div
            className="resizer cursor-col-resize absolute right-650 top-0 bottom-0"
            onMouseDown={startResizing2}
            style={{ userSelect: "none", width: "5px" }}
          ></div>
        </div>
      )}

      {showChat ? (
        <div className="hidden sm:block bg-white overflow-auto border-r border-gray-300 sm:w-full">
          <h1 className="text-2xl py-2 text-gray-800 font-bold hidden text-center sm:block">
            {isCustomerInbox ? "Customer Inbox" : "Agent Inbox"}
          </h1>
          <div className="p-2">
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {isCustomerInbox && (
            <div className="flex flex-col">
              <span className="pl-4 text-base">Count: {filteredCount}</span>
              <span className="flex justify-between">
                <IconButton id="0" onClick={handleFilterChange}>
                  <Avatar
                    sx={{
                      bgcolor:
                        filterButtonClicked === 0 ? "primary.dark" : "#e3e6e5",
                      height: 48,
                      width: 48,
                    }}
                  >
                    <ArrowCircleRightOutlinedIcon
                      style={{ height: 36, width: 36 }}
                    />
                  </Avatar>
                </IconButton>
                <IconButton id="1" onClick={handleFilterChange}>
                  <Avatar
                    sx={{
                      bgcolor:
                        filterButtonClicked === 1 ? "primary.dark" : "#e3e6e5",
                      height: 48,
                      width: 48,
                    }}
                  >
                    <RequestQuoteOutlinedIcon
                      style={{ height: 36, width: 36 }}
                    />
                  </Avatar>
                </IconButton>
                <IconButton id="2" onClick={handleFilterChange}>
                  <Avatar
                    sx={{
                      bgcolor:
                        filterButtonClicked === 2 ? "primary.dark" : "#e3e6e5",
                      height: 48,
                      width: 48,
                    }}
                  >
                    <ShoppingCartCheckoutOutlinedIcon
                      style={{ height: 36, width: 36 }}
                    />
                  </Avatar>
                </IconButton>
                <IconButton id="3" onClick={handleFilterChange}>
                  <Avatar
                    sx={{
                      bgcolor:
                        filterButtonClicked === 3 ? "primary.dark" : "#e3e6e5",
                      height: 48,
                      width: 48,
                    }}
                  >
                    <LocalShippingOutlinedIcon
                      style={{ height: 36, width: 36 }}
                    />
                  </Avatar>
                </IconButton>
                <IconButton id="4" onClick={handleFilterChange}>
                  <Avatar
                    sx={{
                      bgcolor:
                        filterButtonClicked === 4 ? "primary.dark" : "#e3e6e5",
                      height: 48,
                      width: 48,
                    }}
                  >
                    <DoNotDisturbOutlinedIcon
                      style={{ height: 36, width: 36 }}
                    />
                  </Avatar>
                </IconButton>
              </span>
            </div>
          )}
          <List>
            {filteredUsers.map((user) => (
              <React.Fragment key={user.id}>
                <ListItem
                  button
                  className="border-b border-gray-200 hover:bg-blue-100"
                  onClick={() => handleListItemClick(user)}
                >
                  <ListItemIcon>
                    {user.status === "lead" && (
                      <ArrowCircleRightOutlinedIcon
                        style={{ height: 36, width: 36 }}
                      />
                    )}
                    {user.status === "estimate" && (
                      <RequestQuoteOutlinedIcon
                        style={{ height: 36, width: 36 }}
                      />
                    )}
                    {user.status === "order" && (
                      <ShoppingCartCheckoutOutlinedIcon
                        style={{ height: 36, width: 36 }}
                      />
                    )}
                    {user.status === "shipped" && (
                      <LocalShippingOutlinedIcon
                        style={{ height: 36, width: 36 }}
                      />
                    )}
                    {user.status === "no action" && (
                      <DoNotDisturbOutlinedIcon
                        style={{ height: 36, width: 36 }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      !isCustomerInbox ? user.company : user.type === "business"
                        ? user.company
                        : user.name
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          className={classes.secondaryText}
                        >
                          {user.lead} | {user.email} |{" "}
                          {Math.abs(
                            moment
                              .duration(
                                moment(user.lastMessageTimestamp).diff(
                                  moment.now()
                                )
                              )
                              .asHours()
                          ) > 24
                            ? moment(user.lastMessageTimestamp).fromNow()
                            : moment(user.lastMessageTimestamp).format(
                                "hh:mm A"
                              )}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </div>
      ) : (
        <div className="flex-grow flex flex-col border-l border-gray-300 hidden sm:block">
          {currentView === VIEWS.CHAT && (
            <>
              {isCustomerInbox && (
                <>
                  <h1 className="absolute top-1 text-xl left-[40px] z-10 m-2 text-white-A700 font-bold pt-1 px-4 rounded">
                    Chat
                  </h1>

                  <button
                    onClick={() => changeView(VIEWS.PET_SHIPPING)}
                    className="absolute top-1 right-[60px] z-10 m-2 bg-blurmine-500 text-white-A700 font-bold py-2 px-4 rounded"
                  >
                    <RequestQuoteOutlinedIcon></RequestQuoteOutlinedIcon>
                  </button>

                  <button
                    onClick={() => changeView(VIEWS.QUOTE)}
                    className="absolute top-1 right-[120px] z-10 m-2 bg-blurmine-500 text-white-A700 font-bold py-2 px-4 rounded"
                  >
                    <AdbIcon></AdbIcon>
                  </button>

                  <div className="flex justify-between">
                    <IconButton
                      id="0"
                      color={statusButtonClicked === 0 ? "primary" : "default"}
                      onClick={(event) =>
                        handleStatusChange(selectedUser.email, event)
                      }
                    >
                      <ArrowCircleRightOutlinedIcon
                        style={{ height: 36, width: 36 }}
                      />
                    </IconButton>
                    <IconButton
                      id="1"
                      color={statusButtonClicked === 1 ? "primary" : "default"}
                      onClick={(event) =>
                        handleStatusChange(selectedUser.email, event)
                      }
                    >
                      <RequestQuoteOutlinedIcon
                        style={{ height: 36, width: 36 }}
                      />
                    </IconButton>
                    <IconButton
                      id="2"
                      color={statusButtonClicked === 2 ? "primary" : "default"}
                      onClick={(event) =>
                        handleStatusChange(selectedUser.email, event)
                      }
                    >
                      <ShoppingCartCheckoutOutlinedIcon
                        style={{ height: 36, width: 36 }}
                      />
                    </IconButton>
                    <IconButton
                      id="3"
                      color={statusButtonClicked === 3 ? "primary" : "default"}
                      onClick={(event) =>
                        handleStatusChange(selectedUser.email, event)
                      }
                    >
                      <LocalShippingOutlinedIcon
                        style={{ height: 36, width: 36 }}
                      />
                    </IconButton>
                    <IconButton
                      id="4"
                      color={statusButtonClicked === 4 ? "primary" : "default"}
                      onClick={(event) =>
                        handleStatusChange(selectedUser.email, event)
                      }
                    >
                      <DoNotDisturbOutlinedIcon
                        style={{ height: 36, width: 36 }}
                      />
                    </IconButton>
                  </div>
                </>
              )}

              <div
                className={`flex-grow overflow-auto p-4 space-y-4 ${
                  isCustomerInbox ? "h-[80vh]" : "h-[90vh]"
                } overflow-x-auto`}
                ref={messagesEndRef}
              >
                {isCustomerInbox &&
                  messages[selectedUserId]?.map((message) => (
                    <div
                      key={message.id}
                      className={`flex ${
                        message.sender === "admin"
                          ? "justify-end"
                          : "justify-start"
                      }`}
                    >
                      <div
                        className={`rounded px-4 py-2 border border-gray-300 ${
                          message.sender === "admin"
                            ? "bg-gray-1900"
                            : "bg-white-A700"
                        } w-auto max-w-xs break-words`}
                      >
                        {message.text.split("\n").map((line, lineIndex) => {
                          const keyMatch = line.match(/(.*?):\s*(.*)/);
                          let keyStyleClass = classes.keyText;
                          let valueStyleClass = "";

                          if (keyMatch) {
                            const key = keyMatch[1].toLowerCase();
                            switch (key) {
                              case "name":
                              case "phone":
                              case "email":
                                keyStyleClass += ` ${classes.blueText}`;
                                break;
                              case "pet 1 details":
                              case "pet 2 details":
                              case "pet 3 details":
                              case "pet 4 details":
                              case "pet 5 details":
                                keyStyleClass += ` ${classes.greenBoldText}`;
                                break;
                              default:
                                break;
                            }
                          }

                          return (
                            <Box key={lineIndex} my={1}>
                              {keyMatch ? (
                                <>
                                  <Typography
                                    component="span"
                                    className={keyStyleClass}
                                  >
                                    {keyMatch[1]}:
                                  </Typography>{" "}
                                  <Typography
                                    component="span"
                                    className={valueStyleClass}
                                  >
                                    {keyMatch[2]}
                                  </Typography>
                                </>
                              ) : (
                                <Typography component="span">{line}</Typography>
                              )}
                            </Box>
                          );
                        })}{" "}
                        <div className="w-full text-right">
                          {Math.abs(
                            moment
                              .duration(
                                moment(message.timestamp).diff(moment.now())
                              )
                              .asHours()
                          ) > 24
                            ? moment(message.timestamp).fromNow()
                            : moment(message.timestamp).format("hh:mm A")}
                        </div>
                      </div>
                    </div>
                  ))}
                {!isCustomerInbox &&
                  (agentMessages?.length > 0 ? (
                    agentMessages?.map((msg, index) => {
                      return (
                        <div
                          key={index}
                          className={`p-2 rounded-md mb-2 border border-gray-300 ${
                            msg.role === "user"
                              ? "bg-white-A700 self-start mr-10"
                              : "bg-gray-1900 self-end ml-10"
                          }`}
                        >
                          <p className="text-sm whitespace-pre-wrap">
                            {msg.content}
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <div className="p-4 text-xl font-bold object-center">
                      No Conversation
                    </div>
                  ))}
              </div>

              {isCustomerInbox && (
                <div className="p-4 flex-none fixed bottom-0 right-0 z-[9999] w-full">
                  <div className="flex bg-white rounded-lg border">
                    <TextField
                      fullWidth
                      placeholder="Type message here"
                      variant="outlined"
                      size="small"
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      onKeyPress={(e) =>
                        e.key === "Enter" && handleSendMessage()
                      }
                    />
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={handleSendMessage}
                    >
                      <SendIcon />
                    </IconButton>
                  </div>
                </div>
              )}
              {chats?.length > 0 ? (
                <div>
                  <select
                    value={selectedChat}
                    onChange={handleChatChange}
                    className="fixed top-1 right-[10vh] mt-1 bg-transparent border border-blurmine-500 rounded-md shadow-sm focus:border-blurmine-500 focus:ring-blurmine-500 focus:ring-opacity-50"
                  >
                    {chats?.map((chat) => (
                      <option
                        key={chat.conversation_id}
                        value={chat.conversation_id}
                      >
                        Chat {chat.conversation_id + 1}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                ""
              )}
              <button
                className="absolute top-1 right-[5px] z-10 m-2 bg-blurmine-500 text-white-A700 font-bold py-2 px-4 rounded"
                onClick={handleCloseChat}
              >
                <img
                  src="/images/bgarrow.png"
                  alt="back arrow"
                  className="h-4 w-5"
                />
              </button>
              {!isCustomerInbox && (
                <h1 className="absolute top-1 text-xl left-[40px] z-10 m-2 text-white-A700 font-bold pt-1 px-4 rounded">
                  Agent Chat
                </h1>
              )}
            </>
          )}
          {currentView === VIEWS.PET_SHIPPING && (
            <>
              <h1 className="absolute top-1 text-xl left-[40px] z-10 m-2 text-white-A700 font-bold pt-1 px-4 rounded">
                Quote
              </h1>
              <button
                onClick={() => changeView(VIEWS.CHAT)}
                className="absolute top-1 right-[65px] z-10 m-2 bg-blurmine-500 text-white-A700 font-bold py-2 px-4 rounded"
              >
                <ChatOutlinedIcon></ChatOutlinedIcon>
              </button>

              <button
                onClick={() => changeView(VIEWS.QUOTE)}
                className="absolute top-1 right-[5px] z-10 m-2 bg-blurmine-500 text-white-A700 font-bold py-2 px-4 rounded"
              >
                <AdbIcon></AdbIcon>
              </button>
              <PetShippingForm selectedUserId={selectedUserId} />
            </>
          )}

          {currentView === VIEWS.QUOTE && (
            <>
              <h1 className="absolute top-1 text-xl left-[40px] z-10 m-2 text-white-A700 font-bold pt-1 px-4 rounded">
                Assistant
              </h1>
              <button
                onClick={() => changeView(VIEWS.CHAT)}
                className="absolute top-1 right-[65px] z-10 m-2 bg-blurmine-500 text-white-A700 font-bold py-2 px-4 rounded"
              >
                <ChatOutlinedIcon></ChatOutlinedIcon>
              </button>
              <button
                onClick={() => changeView(VIEWS.PET_SHIPPING)}
                className="absolute top-1 right-[5px] z-10 m-2 bg-blurmine-500 text-white-A700 font-bold py-2 px-4 rounded"
              >
                <RequestQuoteOutlinedIcon></RequestQuoteOutlinedIcon>
              </button>

              <AiChatBot key={selectedUserId} clientId={selectedUserId} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default App;
