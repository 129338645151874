import React from "react";
import Routes from "./Routes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function App() {
  return <Routes />;
}

export default App;
