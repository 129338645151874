import React, { useEffect, useState } from "react";
import { getRequest, BaseURL, postRequest } from "apis/axios.handler";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const QuoteDashboard = ({ isAdminDashboard, agentId, company }) => {
  const [bookingInfo, setBookingInfo] = useState({
    customerName: "",
    arrivalDate: "",
    awb: "",
    ref_id: "",
  });
  const [editingRow, setEditingRow] = useState(null);
  const [detailedView, setDetailedView] = useState(null);
  const [filter, setFilter] = useState("");
  const [quotes, setQuotes] = useState([]);

  useEffect(() => {
    if (!editingRow) {
      //Fetch quotes again once confirmed booking
      if (isAdminDashboard) {
        const token = process.env.REACT_APP_AGENT_VERIFICATION_KEY;
        getRequest({
          endpoint: "/getAllImportQuotes",
          params: { token: token },
          options: { baseURL: BaseURL.AIChatService },
        }).then((response) => {
          if (response.data.data) {
            setQuotes(response.data.data);
          }
        });
      } else {
        const token = localStorage.getItem("agentAuthToken");
        getRequest({
          endpoint: "/getCompanyImportQuotes",
          params: { agentId: agentId.toString(), token: token },
          options: { baseURL: BaseURL.AIChatService },
        }).then((response) => {
          if (response.data.data) {
            setQuotes(response.data.data);
          }
        });
      }
    }
  }, [editingRow, agentId, isAdminDashboard]);

  const handleBookClick = (rowIndex) => {
    setEditingRow(rowIndex);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBookingInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleQuoteIdClick = (rowIndex) => {
    setDetailedView(detailedView === rowIndex ? null : rowIndex);
  };

  const handleConfirmBooking = () => {
    if (
      !bookingInfo.arrivalDate ||
      !bookingInfo.awb ||
      !bookingInfo.customerName ||
      !bookingInfo.ref_id
    ) {
      alert("Please fill all required fields");
      return;
    }
    const token = process.env.REACT_APP_AGENT_VERIFICATION_KEY;
    postRequest({
      endpoint: "/bookQuote",
      data: {
        agentId: agentId,
        quoteId: quotes[editingRow].quote_id.$oid,
        customerName: bookingInfo.customerName,
        arrivalDate: bookingInfo.arrivalDate,
        awb: bookingInfo.awb,
        referenceId: bookingInfo.ref_id,
        token: token,
      },
      options: { baseURL: BaseURL.AIChatService },
    })
      .then(() => {
        setEditingRow(null);
        setBookingInfo({
          customerName: "",
          arrivalDate: "",
          awb: "",
          ref_id: "",
        });
      })
      .catch((error) => {
        console.error(error);
        setEditingRow(null);
        setBookingInfo({
          customerName: "",
          arrivalDate: "",
          awb: "",
          ref_id: "",
        });
      });
  };

  function formatPrice(number) {
    let numberStr = Math.round(number).toString();
    let formattedStr = numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedStr;
  }

  function formatDate(date) {
    if (!date) return;
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = months[parseInt(date.substring(5, 7), 10) - 1];
    const day = date.substring(8, 10);
    return `${month}-${String(day).padStart(2, "0")}`;
  }

  function formatName(fullName) {
    let nameParts = fullName?.trim().split(" ");

    if (nameParts?.length > 1) {
      let firstName = nameParts[0];
      let lastName = nameParts.slice(1).join(" ");
      return `${lastName} ${firstName[0]}.`;
    } else {
      return fullName;
    }
  }

  const filteredQuotes = quotes?.filter(
    (quote) =>
      quote.company_name?.toLowerCase().includes(filter.toLowerCase()) ||
      quote.customer_name?.toLowerCase().includes(filter.toLowerCase()) ||
      quote.arrival_airport?.toLowerCase().includes(filter.toLowerCase()) ||
      quote.delivery_destination
        ?.toLowerCase()
        .includes(filter.toLowerCase()) ||
      quote.readable_quote_id?.toLowerCase().includes(filter.toLowerCase()) ||
      quote.awb?.toLowerCase().includes(filter.toLowerCase()) ||
      quote.reference_id?.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <>
      <div className="flex flex-col items-center">
        {isAdminDashboard ? (
          ""
        ) : (
          <h1 className="text-2xl font-bold text-gray-700 flex-grow text-center">
            Smart Pet Air Travel
          </h1>
        )}
        <h1 className="text-xl font-bold text-gray-700 flex-grow text-center">
          {isAdminDashboard ? "Import Quotes Provided" : "Quotes Received"}
        </h1>
      </div>
      {isAdminDashboard && (
        <div className="mt-4">
          <input
            type="text"
            placeholder="Search"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="px-2 py-1 border rounded w-full"
          />
        </div>
      )}
      <div className="overflow-x-auto mt-4">
        <table className="min-w-full divide-y divide-gray-200 mt-4">
          <thead>
            <tr>
              <th className="px-1 py-3 bg-gray-50 text-left text-s font-large text-gray-800 tracking-wider">
                QuoteID
              </th>
              {isAdminDashboard && (
                <th className="px-1 py-3 bg-gray-50 text-left text-s font-large text-gray-800 tracking-wider">
                  Company
                </th>
              )}
              <th className="px-1 py-3 bg-gray-50 text-left text-s font-large text-gray-800 tracking-wider">
                ARR
              </th>
              <th className="px-1 py-3 bg-gray-50 text-left text-s font-large text-gray-800 tracking-wider">
                ARRDt
              </th>
              <th className="px-1 py-3 bg-gray-50 text-left text-s font-large text-gray-800 tracking-wider sm:hidden">
                DeliverTo
              </th>
              <th className="px-1 py-3 bg-gray-50 text-left text-s font-large text-gray-800 tracking-wider sm:hidden">
                Price
              </th>
              <th className="px-1 py-3 bg-gray-50 text-left text-s font-large text-gray-800 tracking-wider">
                Name
              </th>
              <th className="px-1 py-3 bg-gray-50 text-left text-s font-large text-gray-800 tracking-wider sm:hidden">
                Airline
              </th>
              <th className="px-1 py-3 bg-gray-50 text-left text-s font-large text-gray-800 tracking-wider sm:hidden">
                MyRef
              </th>
              <th className="px-1 py-3 bg-gray-50"></th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredQuotes.map((quote, index) => (
              <React.Fragment key={index}>
                <tr key={index}>
                  <td
                    className="px-1 py-4 whitespace-normal break-words text-blue-500 cursor-pointer"
                    onClick={() => handleQuoteIdClick(index)}
                  >
                    {"M" + quote.readable_quote_id?.substring(4, 10)}
                  </td>
                  {isAdminDashboard && (
                    <td className="px-1 py-4 whitespace-normal break-words">
                      {quote.company_name}
                    </td>
                  )}
                  <td className="px-1 py-4 whitespace-normal break-words">
                    {quote.arrival_airport}
                  </td>
                  <td className="px-1 py-4 whitespace-normal break-words">
                    {formatDate(quote.arrival_date)}
                  </td>
                  <td className="px-1 py-4 whitespace-normal break-words sm:hidden">
                    {quote.delivery_destination}
                  </td>
                  <td className="px-1 py-4 whitespace-normal break-words sm:hidden">
                    $
                    {formatPrice(
                      quote.customs_clearance + quote.ground_delivery_total
                    )}
                  </td>
                  <td className="px-1 py-4 whitespace-normal break-words">
                    {formatName(quote.customer_name)}
                  </td>
                  <td className="px-1 py-4 whitespace-normal break-words sm:hidden">
                    {quote.awb?.substring(0, 3)}
                  </td>
                  <td className="px-1 py-4 whitespace-normal break-words sm:hidden">
                    {quote.reference_id?.substring(0, 6)}
                  </td>
                  <td className="px-1 py-4 whitespace-normal break-words">
                    {quote.booked ? (
                      <button className="bg-gray-400 text-stone-50 px-2 py-2 rounded cursor-not-allowed">
                        Booked
                      </button>
                    ) : editingRow === index ? (
                      <div className="w-48">
                        <input
                          type="text"
                          name="customerName"
                          placeholder="Customer Name*"
                          value={bookingInfo.customerName}
                          onChange={handleInputChange}
                          className="mb-2 px-1 py-1 border-transparent rounded w-full"
                        />
                        <div className="my-2">
                          <ReactDatePicker
                            type="date"
                            name="arrivalDate"
                            placeholderText=" Arrival Date*"
                            selected={bookingInfo.arrivalDate}
                            minDate={new Date()}
                            onChange={(date) => {
                              setBookingInfo((prevInfo) => ({
                                ...prevInfo,
                                arrivalDate: date,
                              }));
                            }}
                            className="mb-2 rounded w-full"
                          />
                        </div>
                        <input
                          type="text"
                          name="awb"
                          placeholder="AWB*"
                          value={bookingInfo.awb}
                          onChange={handleInputChange}
                          className="mb-2 px-1 border-transparent rounded w-full"
                        />
                        <input
                          type="text"
                          name="ref_id"
                          placeholder="Your Reference Id*"
                          value={bookingInfo.ref_id}
                          onChange={handleInputChange}
                          className="mb-2 px-1 py-1 border-transparent rounded w-full"
                        />
                        <button
                          onClick={handleConfirmBooking}
                          className="bg-transparent text-gray-700 border-2 border-blurmine-100 hover:bg-gray-200 px-4 py-2 rounded"
                        >
                          Confirm Booking
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={() => handleBookClick(index)}
                        className="bg-blurmine-100 text-stone-50 px-4 py-2 rounded"
                      >
                        Book
                      </button>
                    )}
                  </td>
                </tr>
                {detailedView === index && (
                  <tr>
                    <td
                      colSpan="8"
                      className="px-2 py-4 bg-gray-50 border-t border-gray-200"
                    >
                      <div className="text-sm">
                        <div className="grid grid-cols-2 sm:grid-cols-1 gap-4">
                          <div>
                            <p className="font-medium p-1">
                              <strong>Quote ID:</strong>{" "}
                              {quote.readable_quote_id}
                            </p>
                            <p className="font-medium p-1">
                              <strong>Arrival Airport:</strong>{" "}
                              {quote.arrival_airport}
                            </p>
                            <p className="font-medium p-1">
                              <strong>Deliver To:</strong>{" "}
                              {quote.delivery_destination}
                            </p>
                            <p className="font-medium p-1">
                              <strong>Price:</strong> $
                              {formatPrice(
                                quote.customs_clearance +
                                  quote.ground_delivery_total
                              )}
                            </p>
                          </div>
                          <div>
                            <p className="font-medium p-1">
                              <strong>Customer Name:</strong>{" "}
                              {quote.customer_name}
                            </p>
                            <p className="font-medium p-1">
                              <strong>Arrival Date:</strong>{" "}
                              {formatDate(quote.arrival_date)}
                            </p>
                            <p className="font-medium p-1">
                              <strong>AWB:</strong> {quote.awb}
                            </p>
                            <p className="font-medium p-1">
                              <strong>Quote Generated on:</strong>{" "}
                              {moment(quote.timestamp).format(
                                "DD-MMM-YYYY"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="mt-4 border-t border-gray-200 pt-4">
                          <h3 className="text-lg font-medium text-gray-900">
                            <strong>Comments:</strong>
                          </h3>
                          <div className="grid grid-cols-1 sm:grid-cols-1 gap-4 mt-2">
                            <p className="whitespace-pre-wrap">
                              {quote.comments}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default QuoteDashboard;
