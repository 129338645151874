import React, { useState, useEffect, useMemo } from "react";
import {
  TextField,
  Box,
  Divider,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@material-ui/icons/Send";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MenuItem from "@mui/material/MenuItem";
import Total from "./Total";
import { getPusherInstance } from "PusherService";
import { getRequest, postRequest } from "apis/axios.handler";
import { enqueueSnackbar } from "notistack";

const PetShippingForm = ({
  onTotalPriceChange,
  selectedUserId,
  selectedUserEmail,
}) => {
  const pets = 5; // Number of pets
  const dimensions = ['L"', 'W"', 'H"']; // Dimension labels
  const categories = [
    "Airline",
    "Admin",
    "Crate",
    "Vet",
    "USDA",
    "Origin Ground",
    "Origin Board",
    "Stop Board",
    "Arrival Board",
    "Arrival Ground",
    "Permit Import",
    "Custom",
  ]; // Category labels
  const fields = ["cost", "margin", "price"];

  const fieldLabelMap = {
    cost: "Cost$",
    margin: "Margin$",
    price: "Price$",
  };
  const [message, setMessage] = useState("");
  const [checkedCategories, setCheckedCategories] = useState({
    all: false,
    individual: categories.reduce(
      (state, category) => ({
        ...state,
        [category]: category === "Airline",
      }),
      {}
    ),
  });

  const [dimensionsInputs, setDimensionsInputs] = useState(
    Array.from({ length: pets }, () => ({ name: "", L: "", W: "", H: "" }))
  );

  const [chargeableWeights, setChargeableWeights] = useState(
    Array.from({ length: pets }, () => "")
  );

  const [totalChargedKgs, setTotalChargedKgs] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const currentDate = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  useEffect(() => {
    const total = chargeableWeights.reduce(
      (acc, weight) => acc + (parseFloat(weight) || 0),
      0
    );
    setTotalChargedKgs(total.toFixed(2)); // rounds to 2 decimal places
  }, [chargeableWeights]);
  const [ratePerKg, setRatePerKg] = useState("");
  const [airCargoCharges, setAirCargoCharges] = useState("");
  const [airlineOtherCharges, setAirlineOtherCharges] = useState("");
  const [airlineTotalCharges, setAirlineTotalCharges] = useState("");
  const [quotes, setQuotes] = useState([]);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [routingFrom, setRoutingFrom] = useState("");
  const [routingVia1, setRoutingVia1] = useState("");
  const [routingVia2, setRoutingVia2] = useState("");
  const [routingTo, setRoutingTo] = useState("");
  const [refreshQuotes, setRefreshQuotes] = useState(false);

  const handleAirlineOtherChargesChange = (event) => {
    setAirlineOtherCharges(event.target.value);
  };

  const [values, setValues] = useState(
    categories.reduce((acc, category) => {
      acc[category] = { cost: "", margin: "", price: "" };
      return acc;
    }, {})
  );

  useEffect(() => {
    let newTotalPrice = 0;

    for (const category of categories) {
      if (checkedCategories.individual[category]) {
        const price = parseFloat(values[category]["price"]) || 0;
        newTotalPrice += price;
      }
    }

    setTotalPrice(newTotalPrice.toFixed(2)); // This will trigger the effect to recalculate total price

    if (typeof onTotalPriceChange === "function") {
      onTotalPriceChange(newTotalPrice.toFixed(2)); // Call the callback function
    }
  }, [values, checkedCategories, onTotalPriceChange]); // Make sure to include checkedCategories in the dependency array

  const [totals, setTotals] = useState({ cost: 0, margin: 0, price: 0 });
  const handleInputChange = (category, field, value) => {
    // Update the specific field with the new value
    setValues((prevValues) => {
      const updatedCategoryValues = {
        ...prevValues[category],
        [field]: value,
      };

      // If cost or margin fields are updated, recalculate the price
      if (field === "cost" || field === "margin") {
        const cost = parseFloat(updatedCategoryValues["cost"]) || 0;
        const margin = parseFloat(updatedCategoryValues["margin"]) || 0;
        updatedCategoryValues["price"] = (cost + margin).toFixed(2); // calculate total price
      }

      return {
        ...prevValues,
        [category]: updatedCategoryValues,
      };
    });
  };

  const [selectedOption, setSelectedOption] = useState("");

  const resetForm = () => {
    // Reset all form states to their initial values
    setMessage("");
    setCheckedCategories({
      all: false,
      individual: categories.reduce(
        (state, category) => ({
          ...state,
          [category]: category === "Airline",
        }),
        {}
      ),
    });
    setDimensionsInputs(
      Array.from({ length: pets }, () => ({ L: "", W: "", H: "", name: "" }))
    );
    setChargeableWeights(Array.from({ length: pets }, () => ""));
    setTotalChargedKgs("");
    setRatePerKg("");
    setAirCargoCharges("");
    setAirlineOtherCharges("");
    setAirlineTotalCharges("");

    setRoutingFrom("");
    setRoutingVia1("");
    setRoutingVia2("");
    setRoutingTo("");

    // Reset the totals for cost, margin, and price
    setTotals({ cost: 0, margin: 0, price: 0 });
    // Reset the values for each category
    setValues(
      categories.reduce((acc, category) => {
        acc[category] = { cost: "", margin: "", price: "" };
        return acc;
      }, {})
    );
    // Don't forget to reset any other state that may affect the total or category fields
  };

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedOption(value);

    if (value === "new") {
      // Clear the form and reset state to defaults for a new quote
      resetForm();
    }
  };

  useEffect(() => {
    const newTotals = { cost: 0, margin: 0, price: 0 };

    categories.forEach((category) => {
      if (checkedCategories.individual[category]) {
        fields.forEach((field) => {
          const value = parseFloat(values[category][field]) || 0;
          newTotals[field] += value;
        });
      }
    });

    setTotals({
      cost: newTotals.cost.toFixed(2),
      margin: newTotals.margin.toFixed(2),
      price: newTotals.price.toFixed(2),
    });
  }, [values, checkedCategories]);

  useEffect(() => {
    // Convert to numbers and add them
    const total =
      (parseFloat(airCargoCharges) || 0) +
      (parseFloat(airlineOtherCharges) || 0);
    const totalFormatted = total.toFixed(2);

    setAirlineTotalCharges(totalFormatted);

    setValues((prevValues) => {
      // Here, we assume that if the Airline total charges change, it affects the cost directly.
      const updatedAirlineValues = {
        ...prevValues.Airline,
        cost: totalFormatted, // Set the new cost as the Airline total charges
      };

      // Now add the margin to the cost to get the new price
      const cost = parseFloat(updatedAirlineValues.cost) || 0;
      const margin = parseFloat(updatedAirlineValues.margin) || 0;
      updatedAirlineValues.price = (cost + margin).toFixed(2); // Calculate the total price

      return {
        ...prevValues,
        Airline: updatedAirlineValues, // Update the Airline category with the new values
      };
    });
  }, [airCargoCharges, airlineOtherCharges]);

  useEffect(() => {
    const charges = parseFloat(ratePerKg) * parseFloat(totalChargedKgs);
    setAirCargoCharges(charges.toFixed(2));
  }, [ratePerKg, totalChargedKgs]);

  const handleRateChange = (event) => {
    const { value } = event.target;
    // Allow only valid decimal numbers; this regex allows empty string, full numbers, and decimal numbers
    if (value === "" || value.match(/^\d*\.?\d*$/)) {
      setRatePerKg(value);
    }
  };

  useEffect(() => {
    const charges = (parseFloat(totalChargedKgs) * ratePerKg).toFixed(2);
    setAirCargoCharges(charges);
  }, [totalChargedKgs, ratePerKg]);

  const handleCategoryChange = (event) => {
    const { name, checked } = event.target;
    setCheckedCategories({
      ...checkedCategories,
      individual: {
        ...checkedCategories.individual,
        [name]: checked,
      },
    });
  };

  const handleAllCategoriesChange = (event) => {
    const newCheckedState = event.target.checked;
    setCheckedCategories({
      all: newCheckedState,
      individual: Object.keys(checkedCategories.individual).reduce(
        (state, category) => ({
          ...state,
          [category]: newCheckedState,
        }),
        {}
      ),
    });
  };

  const handleDimensionInput = (petIndex, dimension, value) => {
    const updatedInputs = [...dimensionsInputs];

    // Allow decimal inputs for dimensions, and maintain the input as string to handle inputs like "12.23"
    // Only update the input if it's the pet name or it's a valid number (including decimals)
    if (dimension === "name") {
      // Directly use the string value for pet names
      updatedInputs[petIndex] = {
        ...updatedInputs[petIndex],
        [dimension]: value,
      };
    } else {
      // Here we update the dimension only if the value is empty (allowing deletion) or it's a valid decimal number
      if (value === "" || value.match(/^\d*\.?\d*$/)) {
        updatedInputs[petIndex] = {
          ...updatedInputs[petIndex],
          [dimension]: value,
        };
      }
    }

    setDimensionsInputs(updatedInputs);

    // Calculate the chargeable weight only if all dimensions are valid numbers and not just "."
    if (dimension !== "name") {
      const areAllDimensionsValid = ["L", "W", "H"].every(
        (dim) =>
          updatedInputs[petIndex][dim] === "" ||
          (!isNaN(updatedInputs[petIndex][dim]) &&
            updatedInputs[petIndex][dim].match(/^\d*\.?\d+$/))
      );

      if (areAllDimensionsValid) {
        if (
          updatedInputs[petIndex].L > 0 &&
          updatedInputs[petIndex].W > 0 &&
          updatedInputs[petIndex].H > 0
        ) {
          const chargeableKg = calculateChargeableWeight(
            parseFloat(updatedInputs[petIndex].L),
            parseFloat(updatedInputs[petIndex].W),
            parseFloat(updatedInputs[petIndex].H)
          );
          const updatedWeights = [...chargeableWeights];
          updatedWeights[petIndex] = chargeableKg;
          setChargeableWeights(updatedWeights);
        } else {
          // If any dimension is zero or empty, set chargeable weight to zero
          const updatedWeights = [...chargeableWeights];
          updatedWeights[petIndex] = "0"; // Set to '0' if any dimension is zero
          setChargeableWeights(updatedWeights);
        }
      }
    }
  };

  const calculateChargeableWeight = (length, width, height) => {
    const volumeCm = length * width * height * Math.pow(2.54, 3);
    return (volumeCm / 6000).toFixed(2); // assumes L, W, H are in inches
  };

  useEffect(() => {
    var newTotalPrice = 0;
    categories.forEach((category) => {
      if (checkedCategories.individual[category]) {
        newTotalPrice += parseFloat(values[category]["price"]) || 0;
      }
    });

    setTotalPrice(newTotalPrice.toFixed(2)); // rounds to 2 decimal places
  }, [values]);

  useEffect(() => {
    const fetchQuotes = async () => {
      try {
        const response = await getRequest({
          endpoint: `/quote/getQuotes/${selectedUserId}`,
        });
        let data = response.data;

        setQuotes(data);

        if (data.length > 0) {
          const lastQuoteId = localStorage.getItem(
            `lastQuote_${selectedUserId}`
          );
          if (
            lastQuoteId &&
            data.some((quote) => quote.quoteId.toString() === lastQuoteId)
          ) {
            setSelectedOption(lastQuoteId);
          } else {
            // Default to the first quote for this user
            setSelectedOption(data[data.length - 1].quoteId.toString());
          }
          const existingQuotes =
            JSON.parse(localStorage.getItem("savedQuotes")) || [];

          // Iterate over the fetched quotes and save if not present
          data.forEach((quote) => {
            const exists = existingQuotes.some(
              (savedQuote) => savedQuote.quoteLeadId === quote.quoteLeadId
            );
            if (!exists) {
              existingQuotes.push({
                quoteId: quote.quoteId,
                quoteLeadId: quote.quoteLeadId,
                status: quote.status,
              });
            }
          });

          localStorage.setItem("savedQuotes", JSON.stringify(existingQuotes));
        } else {
          setMessage(""); // Clear message if no quotes
        }
      } catch (error) {
        console.error("Error fetching quotes:", error.message);
        if (error.message === "No quotes found for the specified user") {
          // Reset state variables associated with form fields here
          // Update this part based on your component's state structure
          setMessage("");
          setQuotes([]);
          setValues(
            categories.reduce((acc, category) => {
              acc[category] = { cost: "", margin: "", price: "" };
              return acc;
            }, {})
          );

          setCheckedCategories({
            all: false,
            individual: categories.reduce(
              (state, category) => ({
                ...state,
                [category]: false,
              }),
              {}
            ),
          });
          setDimensionsInputs(
            Array.from({ length: pets }, () => ({ L: "", W: "", H: "" }))
          );
          setChargeableWeights(Array.from({ length: pets }, () => ""));
          setTotalChargedKgs("");
          setTotalPrice(0);
          setRatePerKg("");
          setAirCargoCharges("");
          setAirlineOtherCharges("");
          setAirlineTotalCharges("");
          setSelectedOption(""); // If you have a selected option for quotes
          // ...and other state variables you might need to reset
        }
      }
    };

    if (selectedUserId) {
      fetchQuotes();
    }
  }, [selectedUserId, refreshQuotes]); // Dependency array

  useEffect(() => {
    // Find the quote object that matches `selectedOption`
    const quote = quotes.find((q) => q.quoteId.toString() === selectedOption);
    setSelectedQuote(quote);
  }, [selectedOption, quotes]); // Depend on `selectedOption` and `quotes`

  useEffect(() => {
    if (selectedQuote) {
      setRoutingFrom(selectedQuote?.routing?.from || "");
      setRoutingVia1(selectedQuote?.routing?.via1 || "");
      setRoutingVia2(selectedQuote?.routing?.via2 || "");
      setRoutingTo(selectedQuote?.routing?.to || "");

      // Assuming the selectedQuote has the totalChargedKgs property directly
      const newTotalChargedKgs = selectedQuote.totalChargedKgs;
      setTotalChargedKgs(newTotalChargedKgs.toString()); // Ensure it's a string if necessary
      setRatePerKg(selectedQuote.rates.ratePerKg.toString());
      setAirCargoCharges(selectedQuote.rates.airCargoCharges.toString());
      setAirlineOtherCharges(
        selectedQuote.rates.airlineOtherCharges.toString()
      );
      setAirlineTotalCharges(
        selectedQuote.rates.airlineTotalCharges.toString()
      );

      // Update dimensionsInputs and chargeableWeights for pets
      const newDimensionsInputs = selectedQuote.pets.map((pet) => ({
        name: pet.petName || "",
        L: pet.dimensions.length.replace(" cm", ""), // Remove ' cm' if present
        W: pet.dimensions.width.replace(" cm", ""),
        H: pet.dimensions.height.replace(" cm", ""),
      }));
      const newChargeableWeights = selectedQuote.pets.map((pet) =>
        pet.chargedKg.replace(" kg", "")
      ); // Remove ' kg' if present

      setDimensionsInputs(newDimensionsInputs);
      setChargeableWeights(newChargeableWeights);

      // Start by cloning the initial state
      const newValues = categories.reduce((acc, category) => {
        acc[category] = { cost: "", margin: "", price: "" };
        return acc;
      }, {});
      const newCheckedCategories = {
        all: false,
        individual: categories.reduce(
          (state, category) => ({
            ...state,
            [category]: category === "Airline",
          }),
          {}
        ),
      };

      // Define a conversion map from API category names to component state keys
      const conversionMap = {
        airline: "Airline",
        admin: "Admin",
        crate: "Crate",
        vet: "Vet",
        usda: "USDA",
        "origin ground": "Origin Ground",
        "origin board": "Origin Board",
        "stop board": "Stop Board",
        "arrival board": "Arrival Board",
        "arrival ground": "Arrival Ground",
        "permit import": "Permit Import",
        custom: "Custom",
      };

      Object.entries(selectedQuote.categories).forEach(([key, value]) => {
        const stateKey = conversionMap[key];
        if (stateKey) {
          newCheckedCategories.individual[stateKey] = true;
          newValues[stateKey] = value;
        }
      });

      setValues(newValues);
      setCheckedCategories(newCheckedCategories);
    }
  }, [selectedQuote, pets]);

  useEffect(() => {
    // Initialize Pusher and subscribe to the 'chat' channel
    const pusher = getPusherInstance();

    const channel = pusher.subscribe("chat");
    channel.bind("message", (data) => {
      console.log(data);
      // Assuming the incoming data has a property 'userId'
      // Only add the message to the chat history if it's from another user
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);

  const handleSave = async () => {
    const transformedCategories = Object.keys(values).reduce(
      (acc, category) => {
        const { cost, margin, price } = values[category];
        const categoryName = category.toLowerCase();
        if (checkedCategories.individual[category]) {
          acc[categoryName] = {
            cost: parseFloat(cost),
            margin: parseFloat(margin),
            price: parseFloat(price),
            included: checkedCategories.individual[category],
          };
        }
        return acc;
      },
      {}
    );

    // Calculate the totals from your totals state
    const totalCost = categories.reduce(
      (acc, category) => acc + parseFloat(values[category].cost || 0),
      0
    );
    const totalMargin = categories.reduce(
      (acc, category) => acc + parseFloat(values[category].margin || 0),
      0
    );
    const totalPrice = categories.reduce(
      (acc, category) => acc + parseFloat(values[category].price || 0),
      0
    );

    // Include the totals in the categories
    transformedCategories.total = {
      cost: totalCost.toFixed(2),
      margin: totalMargin.toFixed(2),
      price: totalPrice.toFixed(2),
    };

    // Retrieve existing quotes from local storage
    const existingQuotes =
      JSON.parse(localStorage.getItem("savedQuotes")) || [];

    // Assuming `selectedOption` holds the `quoteId` and you can find the matching quote in your quotes state to get its quoteLeadId
    const selectedQuote = quotes.find(
      (quote) => quote.quoteId.toString() === selectedOption
    );
    const currentQuoteId = selectedQuote ? selectedQuote.quoteId : null;

    let payload = {
      userId: selectedUserId, // Replace with selectedUserId if it should be dynamic
      quotes: [
        {
          routing: {
            from: routingFrom,
            via1: routingVia1,
            via2: routingVia2,
            to: routingTo,
          },
          rates: {
            ratePerKg: parseFloat(ratePerKg),
            airCargoCharges: parseFloat(airCargoCharges),
            airlineOtherCharges: parseFloat(airlineOtherCharges),
            airlineTotalCharges: parseFloat(airlineTotalCharges),
          },
          categories: transformedCategories,
          timestamp: new Date().toISOString(),
          pets: dimensionsInputs.map((input, index) => ({
            dimensions: {
              length: `${input.L} cm`,
              width: `${input.W} cm`,
              height: `${input.H} cm`,
            },
            petNumber: index + 1,
            petName: input.name,
            chargedKg: `${chargeableWeights[index]} kg`,
          })),
          totalChargedKgs: parseFloat(totalChargedKgs),
          comments: message,
        },
      ],
    };
    if (currentQuoteId) {
      const matchingQuote = existingQuotes.find(
        (quote) => quote.quoteId === currentQuoteId
      );
      if (matchingQuote) {
        payload.quotes[0] = {
          ...payload.quotes[0],
          quoteId: matchingQuote.quoteId,
          quoteLeadId: matchingQuote.quoteLeadId,
          status: matchingQuote.status,
        };
      }
    }

    try {
      const response = await postRequest({
        endpoint: "/quote/saveQuote",
        data: payload,
      });

      if (response.status !== 200) throw new Error("Failed to save quote.");
      setRefreshQuotes((prev) => !prev);
      const data = response.data;
      enqueueSnackbar("Quote saved successfully", { variant: "success" });
    } catch (error) {
      console.error("Error saving the quote:", error);
      // Handle error as before
    }
  };

  const handleSave2 = async () => {
    const transformedCategories = Object.keys(values).reduce(
      (acc, category) => {
        const { cost, margin, price } = values[category];
        const categoryName = category.toLowerCase();
        if (checkedCategories.individual[category]) {
          acc[categoryName] = {
            cost: parseFloat(cost),
            margin: parseFloat(margin),
            price: parseFloat(price),
            included: checkedCategories.individual[category],
          };
        }
        return acc;
      },
      {}
    );

    // Calculate the totals from your totals state
    const totalCost = categories.reduce(
      (acc, category) => acc + parseFloat(values[category].cost || 0),
      0
    );
    const totalMargin = categories.reduce(
      (acc, category) => acc + parseFloat(values[category].margin || 0),
      0
    );
    const totalPrice = categories.reduce(
      (acc, category) => acc + parseFloat(values[category].price || 0),
      0
    );

    // Include the totals in the categories
    transformedCategories.total = {
      cost: totalCost.toFixed(2),
      margin: totalMargin.toFixed(2),
      price: totalPrice.toFixed(2),
    };

    // Retrieve existing quotes from local storage
    const existingQuotes =
      JSON.parse(localStorage.getItem("savedQuotes")) || [];

    // Assuming `selectedOption` holds the `quoteId` and you can find the matching quote in your quotes state to get its quoteLeadId
    const selectedQuote = quotes.find(
      (quote) => quote.quoteId.toString() === selectedOption
    );
    const currentQuoteLeadId = selectedQuote ? selectedQuote.quoteLeadId : null;

    let payload = {
      userId: selectedUserId, // Replace with selectedUserId if it should be dynamic
      quotes: [
        {
          routing: {
            from: routingFrom,
            via1: routingVia1,
            via2: routingVia2,
            to: routingTo,
          },
          rates: {
            ratePerKg: parseFloat(ratePerKg),
            airCargoCharges: parseFloat(airCargoCharges),
            airlineOtherCharges: parseFloat(airlineOtherCharges),
            airlineTotalCharges: parseFloat(airlineTotalCharges),
          },
          categories: transformedCategories,
          timestamp: new Date().toISOString(),
          pets: dimensionsInputs.map((input, index) => ({
            dimensions: {
              length: `${input.L} cm`,
              width: `${input.W} cm`,
              height: `${input.H} cm`,
            },
            petNumber: index + 1,
            petName: input.name,
            chargedKg: `${chargeableWeights[index]} kg`,
          })),
          totalChargedKgs: parseFloat(totalChargedKgs),
          comments: message,
        },
      ],
    };

    if (currentQuoteLeadId) {
      const matchingQuote = existingQuotes.find(
        (quote) => quote.quoteLeadId === currentQuoteLeadId
      );
      if (matchingQuote) {
        payload.quotes[0] = {
          ...payload.quotes[0],
          quoteId: matchingQuote.quoteId,
          quoteLeadId: matchingQuote.quoteLeadId,
          status: "sent",
        };
      }
    }

    try {
      const response = await postRequest({
        endpoint: "/quote/sendEstimate",
        data: payload,
      });
      setRefreshQuotes((prev) => !prev);
      if (response.status !== 200) throw new Error("Failed to save quote.");

      const data = response.data;
      enqueueSnackbar("Quote sent successfully!", { variant: "success" });
      // Handle additional actions after successful save, like updating UI or local storage.
      postRequest({
        endpoint: "/chat/messages",
        data: {
          text: `Your customized price quote is: $${totalPrice}\nSent on ${currentDate} to your email ${selectedUserEmail}.`,
          sender: "admin",
          userId: selectedUserId,
        },
      });
    } catch (error) {
      console.error("Error saving the quote:", error);
      // Handle error as before
    }
  };

  useEffect(() => {
    const selectedQuote = quotes.find(
      (q) => q.quoteId.toString() === selectedOption
    );
    setMessage(selectedQuote?.comments || "");
  }, [selectedOption, quotes]);

  const isSaveDisabled = useMemo(() => {
    if (selectedOption === "new") return false; // Save should be enabled for new quotes

    const selectedQuoteStatus = quotes.find(
      (quote) => quote.quoteId.toString() === selectedOption
    )?.status;
    return selectedQuoteStatus === "sent"; // Disable save if the status is 'Sent'
  }, [selectedOption, quotes]);

  useEffect(() => {
    // Reset form whenever selectedUserId changes
    resetForm();
    // Potentially other logic to fetch new quotes or related information for the new user
  }, [selectedUserId]); // Dependency array includes selectedUserId to trigger effect on its change

  const handleCopyQuote = () => {
    const selectedQuote = quotes.find(
      (q) => q.quoteId.toString() === selectedOption
    );
    if (!selectedQuote) return;

    const copiedQuote = { ...selectedQuote, quoteId: "new", isCopy: true };

    setDimensionsInputs(
      copiedQuote.pets.map((pet) => ({
        name: pet.petName || "",
        L: pet.dimensions.length.replace(" cm", ""), // Remove ' cm' if present
        W: pet.dimensions.width.replace(" cm", ""),
        H: pet.dimensions.height.replace(" cm", ""),
      }))
    );

    setTotalPrice(copiedQuote.totalPrice);

    setSelectedOption("new");
  };

  const isQuoteSent = useMemo(() => {
    const selectedQuote = quotes.find(
      (q) => q.quoteId.toString() === selectedOption
    );
    return selectedQuote?.status === "sent";
  }, [selectedOption, quotes]);

  useEffect(() => {
    localStorage.setItem(`lastQuote_${selectedUserId}`, selectedOption);
  }, [selectedOption, selectedUserId]);

  return (
    <Box className="relative">
      <div className="flex flex-col items-center p-4 z-30 top-0 bg-white-A700 shadow-md justify-between absolute w-full overflow-x-auto">
        <div className="flex items-center w-full pl-2">
          <Total totalPrice={totalPrice} />
        </div>

        <div className="flex items-center w-full mt-2">
          <Box className="flex flex-row gap-2 justify-space-between sm:gap-1">
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              sx={{
                backgroundColor: "teal",
                color: "white",
                height: "30px",
                marginLeft: "5px",
                paddingRight: "5px",
              }}
              onClick={handleSave}
              disabled={isSaveDisabled}
            >
              <p className="sm:hidden">Save</p>
            </Button>
            <Button
              variant="contained"
              startIcon={<SendIcon />}
              sx={{
                backgroundColor: "red",
                color: "white",
                height: "30px",
                marginLeft: "5px",
                paddingRight: "5px",
              }}
              onClick={handleSave2}
            >
              <p className="sm:hidden">Send</p>
            </Button>
            <Button
              variant="contained"
              startIcon={<ContentCopyIcon />} // Assuming you import ContentCopyIcon from @mui/icons-material
              sx={{
                backgroundColor: "gray",
                color: "white",
                height: "30px",
                marginLeft: "5px",
                paddingRight: "5px",
              }}
              onClick={handleCopyQuote}
            >
              <p className="sm:hidden">Copy</p>
            </Button>
          </Box>

          <TextField
            select
            value={selectedOption}
            onChange={handleSelectChange}
            variant="outlined"
            size="small"
            InputProps={{
              style: {
                height: "35px",
                padding: "0 14px",
                marginLeft: "10px",
              },
            }}
            InputLabelProps={{
              style: {
                textAlign: "left",
              },
            }}
          >
            {quotes.map((quote) => (
              <MenuItem key={quote.quoteId} value={quote.quoteId.toString()}>
                {`V ${quote.quoteId} - ${quote.status}`}
              </MenuItem>
            ))}
            <MenuItem value="new">New Quote</MenuItem>
          </TextField>
        </div>
      </div>

      <form className="p-4 bg-white shadow-lg rounded-md overflow-y-auto h-[90vh] pt-[145px]">
        <div className="space-y-6">
          <Box className="bg-white p-2 shadow rounded">
            <Typography variant="h6" className="font-semibold mb-2 text-sm">
              Routing
            </Typography>
            <div className="grid grid-cols-12 gap-2">
              <TextField
                label="FROM"
                variant="outlined"
                size="small"
                className="col-span-3"
                InputLabelProps={{ shrink: true }}
                style={{ backgroundColor: "#ffffff" }}
                value={routingFrom}
                onChange={(e) => setRoutingFrom(e.target.value)}
                disabled={isQuoteSent}
              />
              <TextField
                label="VIA"
                variant="outlined"
                size="small"
                className="col-span-3"
                InputLabelProps={{ shrink: true }}
                style={{ backgroundColor: "#ffffff" }}
                value={routingVia1}
                onChange={(e) => setRoutingVia1(e.target.value)}
                disabled={isQuoteSent}
              />
              <TextField
                label="VIA"
                variant="outlined"
                size="small"
                className="col-span-3"
                InputLabelProps={{ shrink: true }}
                style={{ backgroundColor: "#ffffff" }}
                value={routingVia2}
                onChange={(e) => setRoutingVia2(e.target.value)}
                disabled={isQuoteSent}
              />
              <TextField
                label="TO"
                variant="outlined"
                size="small"
                className="col-span-3"
                InputLabelProps={{ shrink: true }}
                style={{ backgroundColor: "#ffffff" }}
                value={routingTo}
                onChange={(e) => setRoutingTo(e.target.value)}
                disabled={isQuoteSent}
              />
            </div>
          </Box>

          <Divider className="my-4" />

          <Box className="bg-white p-2 shadow rounded space-y-2">
            {Array.from({ length: pets }, (_, petIndex) => (
              <div key={petIndex} className="grid grid-cols-12 gap-2 items-end">
                <TextField
                  label={`Pet ${petIndex + 1}`}
                  variant="outlined"
                  size="small"
                  className="col-span-3"
                  value={dimensionsInputs[petIndex]?.name}
                  onChange={(e) =>
                    handleDimensionInput(petIndex, "name", e.target.value)
                  }
                  InputLabelProps={{ shrink: true }}
                  style={{ backgroundColor: "#ffffff" }}
                  disabled={isQuoteSent}
                />

                {dimensions.map((dim, dimIndex) => (
                  <TextField
                    key={dimIndex}
                    label={dim}
                    variant="outlined"
                    size="small"
                    className={`col-span-${
                      dimIndex === dimensions.length - 1 ? "2" : "2"
                    }`}
                    value={
                      dimensionsInputs[petIndex]?.[dim.replace('"', "")] ?? ""
                    } // Use optional chaining and fallback
                    onChange={(e) =>
                      handleDimensionInput(
                        petIndex,
                        dim.replace('"', ""),
                        e.target.value
                      )
                    }
                    InputLabelProps={{ shrink: true }}
                    style={{ backgroundColor: "#ffffff" }}
                    disabled={isQuoteSent}
                  />
                ))}

                <TextField
                  label="Chg. Kg"
                  variant="outlined"
                  size="small"
                  className="col-span-3"
                  value={chargeableWeights[petIndex]}
                  InputLabelProps={{ shrink: true }}
                  style={{ backgroundColor: "#ffffff" }}
                  disabled={isQuoteSent}
                />
              </div>
            ))}

            <div className="grid grid-cols-12 gap-2 items-end">
              <div className="flex justify-end col-span-9">
                <Typography variant="subtitle1">Total charged Kgs</Typography>
              </div>
              <TextField
                label="Total charged Kgs"
                variant="outlined"
                size="small"
                className="col-span-3"
                value={totalChargedKgs}
                InputLabelProps={{ shrink: true }}
                style={{ backgroundColor: "#ffffff" }}
                disabled={isQuoteSent}
              />
            </div>

            <div className="grid grid-cols-12 gap-2 items-end">
              <div className="flex justify-end col-span-9">
                <Typography variant="subtitle1">Rate $ per Kg</Typography>
              </div>
              <TextField
                label="Rate $ per Kg"
                value={ratePerKg}
                variant="outlined"
                size="small"
                className="col-span-3"
                onChange={handleRateChange}
                InputLabelProps={{ shrink: true }}
                style={{ backgroundColor: "#ffffff" }}
                disabled={isQuoteSent}
              />
            </div>

            <div className="grid grid-cols-12 gap-2 items-end">
              <div className="flex justify-end col-span-9">
                <Typography variant="subtitle1">Air cargo charges $</Typography>
              </div>
              <TextField
                label="Air cargo charges $"
                variant="outlined"
                size="small"
                className="col-span-3"
                value={airCargoCharges}
                InputLabelProps={{ shrink: true }}
                style={{ backgroundColor: "#ffffff" }}
                disabled={isQuoteSent}
              />
            </div>

            <div className="grid grid-cols-12 gap-2 items-end">
              <div className="flex justify-end col-span-9">
                <Typography variant="subtitle1">
                  Airline other charges $
                </Typography>
              </div>
              <TextField
                label="Airline other charges $"
                variant="outlined"
                size="small"
                className="col-span-3"
                onChange={handleAirlineOtherChargesChange}
                value={airlineOtherCharges}
                InputLabelProps={{ shrink: true }}
                style={{ backgroundColor: "#ffffff" }}
                disabled={isQuoteSent}
              />
            </div>

            <div className="grid grid-cols-12 gap-2 items-end">
              <div className="flex justify-end col-span-9">
                <Typography variant="subtitle1">
                  Airline total charges $
                </Typography>
              </div>
              <TextField
                label="Airline total charges $"
                variant="outlined"
                size="small"
                className="col-span-3"
                value={airlineTotalCharges}
                InputLabelProps={{ shrink: true }}
                style={{ backgroundColor: "#ffffff" }}
                disabled={isQuoteSent}
              />
            </div>
          </Box>

          <Divider className="my-4" />

          <Box className="bg-white p-2 shadow rounded space-y-2">
            <div className="grid grid-cols-12 gap-2 items-center">
              <Typography variant="h6" className="col-span-2 text-sm">
                Category
              </Typography>
              <div className="col-span-9"></div>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={checkedCategories.all}
                    onChange={handleAllCategoriesChange}
                    name="all"
                  />
                }
                label=""
                className="col-span-1 justify-right"
              />
            </div>
            {categories.map((category, index) => (
              <div key={index} className="grid grid-cols-12 gap-2 items-center">
                <Typography variant="subtitle1" className="col-span-2 text-sm">
                  {category}
                </Typography>
                {fields.map((field, fieldIndex) => (
                  <TextField
                    key={fieldIndex}
                    label={fieldLabelMap[field]}
                    variant="outlined"
                    size="small"
                    className="col-span-3"
                    value={values[category][field]}
                    onChange={(e) =>
                      handleInputChange(category, field, e.target.value)
                    }
                    InputLabelProps={{ shrink: true }}
                    disabled={
                      !checkedCategories.individual[category] || isQuoteSent
                    }
                    InputProps={{
                      readOnly: field === "price",
                    }}
                    style={{
                      backgroundColor: !checkedCategories.individual[category]
                        ? ""
                        : "#ffffff",
                    }}
                  />
                ))}

                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={checkedCategories.individual[category]}
                      onChange={handleCategoryChange}
                      name={category}
                      disabled={isQuoteSent}
                    />
                  }
                  label=""
                  className="col-span-1 justify-right"
                />
              </div>
            ))}

            <div className="grid grid-cols-12 gap-2 items-center">
              <Typography variant="subtitle1" className="col-span-2 text-sm">
                Total
              </Typography>
              {fields.map((field, fieldIndex) => (
                <TextField
                  key={fieldIndex}
                  variant="outlined"
                  size="small"
                  className="col-span-3"
                  value={totals[field]}
                  InputLabelProps={{ shrink: true }}
                  style={{ backgroundColor: "#ffffff" }}
                  disabled={isQuoteSent}
                />
              ))}
              {/* Empty space for the checkbox in the total row */}
              <div className="col-span-1"></div>
            </div>
          </Box>

          <Box className="flex items-center w-full">
            <textarea
              id="additionalComments"
              name="additionalComments"
              placeholder="Write comments..."
              className="w-full shadow appearance-none border resize-none border-gray-400 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              rows="4"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              disabled={isQuoteSent}
              onInput={(e) => {
                e.target.style.height = "auto";
                e.target.style.height = `${e.target.scrollHeight}px`;
                if (parseInt(e.target.style.height) > 250) {
                  // 200px as max-height example
                  e.target.style.height = "250px";
                  e.target.style.overflowY = "scroll";
                } else {
                  e.target.style.overflowY = "hidden";
                }
              }}
            ></textarea>
          </Box>
        </div>
      </form>
    </Box>
  );
};

export default PetShippingForm;
