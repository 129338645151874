import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import NotFound from "pages/NotFound";
import SideBar from "pages/AdminPanel/SideBarMain";
import { SnackbarProvider } from "notistack";
import IPATAChat from "pages/IPATAChat";
const RFQ = React.lazy(() => import("pages/RFQ"));
const Home = React.lazy(() => import("pages/Home"));
const IPATALogin = React.lazy(() => import("pages/IPATA"));
const Privacy = React.lazy(() => import("pages/Privacy"));

const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/rfq" element={<RFQ />} />
          <Route path="/admin" element={<SideBar />} />
          <Route path="/ipata" element={<IPATALogin />} />
          <Route path="/ipata-chat" element={<IPATAChat />} />
          <Route path="/estimate" element={<Navigate to="/rfq" />} />
          <Route path="/faq" element={<Navigate to="/" />} />
          <Route path="/about-us" element={<Navigate to="/" />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </Router>
      <SnackbarProvider />
    </React.Suspense>
  );
};
export default ProjectRoutes;
