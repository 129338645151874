import { useGoogleLogin } from "@react-oauth/google";
import { postRequest } from "apis/axios.handler";
import React from "react";
import { GoogleLoginButton } from "react-social-login-buttons";

function GoogleLoginComponent({ handleSignIn, setLoading }) {
  const login = useGoogleLogin({
    onSuccess: async (data) => {
      setLoading(true);
      const payload = {
        credential: data.code,
        referer: window.location.protocol + "//" + window.location.host,
      };
      const response = await postRequest({
        endpoint: "/auth/google-login",
        data: payload,
      });
      if (response && response.data && response.data.validated) {
        localStorage.setItem("authToken", response.data.token);
        handleSignIn();
        setLoading(false);
      }
    },
    flow: "auth-code",
  });

  return (
    <div id="signInButton">
      <GoogleLoginButton onClick={() => login()} style={{ height: "40px" }} />
    </div>
  );
}

export default GoogleLoginComponent;
