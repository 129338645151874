const { getRequest, postRequest } = require("./axios.handler");

async function getUsersAPI() {
  const response = await getRequest({ endpoint: "/user/users" });
  return response.data.map((user) => ({
    id: user.id,
    type: user.type,
    name: user.rfqs[0]?.name,
    company: user.rfqs[0]?.company || "",
    email: user.email,
    lastMessageTimestamp: user.lastMessageTimestamp,
    lead: user.leadId,
    status: user.status,
    rfqs: user.rfqs
  }));
}

async function updateMessages(messagePayload) {
  postRequest({ endpoint: "/chat/messages", data: messagePayload })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else if (
        response.headers.get("content-type")?.includes("application/json")
      ) {
        return response;
      } else {
        console.log("Received non-JSON response from server");
        return response; // You could process the text response if needed
      }
    })
    .then((data) => {
      // Process the data or just confirm it was sent
      console.log("Message sent and server responded with:", data);
    })
    .catch((error) => {
      console.error("Error sending message:", error);
    });
}

async function fetchChatHistory(selectedUserId) {
  try {
    const response = await getRequest({
      endpoint: `/chat/messages/${selectedUserId}`,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching chat history:", error);
  }
}

function changeUserStatus(userEmail, statusId) {
  postRequest({ endpoint: "/user/status", data: { email: userEmail, status: statusId } })
  .then((response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } else if (
      response.headers.get("content-type")?.includes("application/json")
    ) {
      return response;
    } else {
      console.log("Received non-JSON response from server");
      return response;
    }
  })
  .then((data) => {
    console.log("Status updated and server responded with:", data);
  })
  .catch((error) => {
    console.error("Error updating status:", error);
  });
}

module.exports = {
  getUsersAPI,
  updateMessages,
  fetchChatHistory,
  changeUserStatus
};
